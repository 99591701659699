
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { UserService } from "./user.service";
import { environment } from "../../../environments/environment";
import {catchError} from "rxjs/operators";


@Injectable()
export class ApiAuthS3Service {

  constructor(
    private http: HttpClient,
    private userService: UserService) { }

  private setHeaders(header: Map<string, string>): HttpHeaders {
    const isIE11 = document['documentMode'];

    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };

    if (isIE11) {
      headersConfig['If-Modified-Since'] = `${'Sat, 01 Jan 2000 00:00:00 GMT'}`;
      headersConfig['Pragma'] = `${'no-cache'}`;
      headersConfig['Expires'] = `${'Sat, 01 Jan 2000 00:00:00 GMT'}`;
    }

    if (header) {
      header.forEach((value, key) => {
        headersConfig[key] = value;
      });
    }

    return new HttpHeaders(headersConfig);
  }

  private formatErrors(error: any) {
    return observableThrowError(error);
  }

  getUserS3Info(license, isAdmin): Observable<any> {
    let userLicenseModel = {
      fullName: this.userService.getUser().fullname,
      login: this.userService.getUser().login
    };
    const header = new Map();
    header.set("license", license);
    // header.set("isAdmin", isAdmin);

    return this.http.post(`${environment.api_url_auth_s3}/auth`, JSON.stringify(userLicenseModel), {headers: this.setHeaders(header)})
      .pipe(catchError(this.formatErrors));
  }
}

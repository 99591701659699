// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  name:"ustore",
  production: false,
  auth_type: 'DEFAULT',
  domain: 'usto.re',

  // ------------https----------

  // api_url: 'https://v3back.usto.re',
  // api_url_s3: 'https://s3.usto.re',
  // api_url_restore: 'https://restore.usto.re',
  // api_url_auth_s3: 'https://udrive-api.usto.re/auth/',
  // api_roles_s3: 'https://roles.usto.re',
  // api_share: 'https://share.usto.re',
  // share_environment: 'https://udrive.usto.re',
  // api_zimbra: 'https://zm-udrive.usto.re',
  // api_ebcorp: 'https://ebdrive-ebcorp.eb.mil.br',

  // ------------https----------
  //
  api_url: 'https://homolog.udrive-v3back.cloudmg.mg.gov.br',
  api_url_s3: 'https://homolog.udrive-s3server.cloudmg.mg.gov.br',
  api_url_restore: 'https://homolog.udrive-restore.cloudmg.mg.gov.br',
  api_url_auth_s3: 'https://homolog.udrive-auth.cloudmg.mg.gov.br',
  api_roles_s3: 'https://homolog.udrive-roles.cloudmg.mg.gov.br',
  api_share: 'https://homolog.udrive-share.cloudmg.mg.gov.br',
  share_environment: 'https://homolog.udrive.cloudmg.mg.gov.br',

  api_zimbra: 'https://homolog.udrive-zm.cloudmg.mg.gov.br',
  api_ebcorp: 'https://ebdrive-ebcorp.eb.mil.br',

};

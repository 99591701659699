import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PrivatePageGuard} from '../../shared/services/private-page.guard';
import { HomeComponent } from './home.component';
import { MainComponent } from './main/main.component';
import {VpnComponent} from './vpn/vpn.component';
import {RestoreComponent} from './restore/restore.component';
import {BackupSchedulerComponent} from './backup-scheduler/backup-scheduler.component';
import {NewUserComponent} from './account/new-user/new-user.component';
import {AccountComponent} from './account/account.component';
import {VpnAccessComponent} from './vpn-access/vpn-access.component';
import {NewUserOfficeComponent} from './account/new-user-office/new-user-office.component';
import {ListUserOfficeComponent} from './account/list-user-office/list-user-office.component';
const routes: Routes = [
    {
      path: '',
      component: HomeComponent,
      canActivate: [PrivatePageGuard],
      children: [
        {
          path: '',
          component: MainComponent,
          canActivate: [PrivatePageGuard]
        },
        {
          path: 'vpn/permission',
          component: VpnComponent,
          canActivate: [PrivatePageGuard]
        },
        {
          path: 'vpn/access',
          component: VpnAccessComponent,
          canActivate: [PrivatePageGuard]
        },
        {
          path: 'restore',
          component: RestoreComponent,
          canActivate: [PrivatePageGuard]
        },
        {
          path: 'backup-scheduler',
          component: BackupSchedulerComponent,
          canActivate: [PrivatePageGuard]
        },
        {
          path: 'user/form',
          component: NewUserComponent,
          canActivate: [PrivatePageGuard]
        },
        {
          path: 'user/office',
          component: NewUserOfficeComponent,
          canActivate: [PrivatePageGuard]
        },
        {
          path: 'user/form/:id',
          component: NewUserComponent,
          canActivate: [PrivatePageGuard]
        },
        {
          path: 'user/list',
          component: AccountComponent,
          canActivate: [PrivatePageGuard]
        },
        {
          path: 'user/list-office',
          component: ListUserOfficeComponent,
          canActivate: [PrivatePageGuard]
        }
      ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }

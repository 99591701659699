<app-modal-basic class="resend-email" #modalResendEmail>
  <div class="app-modal-header">
    <button type="button" class="close basic-close" (click)="goToLogin()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
      <label class="resend-title text-center">{{'resend-email.title' | translate}}</label>
      <button type="button" class="btn btn-primary waves-light"
              (click)="doResendEmail()">
              {{'resend-email.resend' | translate}}
      </button>
      <button type="button" class="btn btn-danger" (click)="modalResendEmail.hide()">{{'resend-email.try' | translate}}</button>
      <label class="resend-subtitle small">{{'resend-email.sub-title' | translate}}</label>
  </div>
  <div class="app-modal-footer">
    
  </div>
</app-modal-basic>

import {AfterViewInit, Component,  ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {TranslateService} from '@ngx-translate/core';
import {MatTableDataSource} from '@angular/material/table';
import {VpnMetadata} from '../../../shared/domains/metadata/vpn-metadata';
import {ZimbraService} from '../vpn/zimbra.service';
import {SpinnerService} from '../../../shared/spinner/spinner.service';
import {UserService} from '../../../shared/services/user.service';
import {NotificationService} from '../../../shared/services/notification.service';
import {ToastyService} from 'ng2-toasty';
import {Router} from '@angular/router';

@Component({
  selector: 'app-vpn-access',
  templateUrl: './vpn-access.component.html',
  styleUrls: ['./vpn-access.component.scss']
})
export class VpnAccessComponent implements AfterViewInit {

  @ViewChild(MatPaginator) matPaginator: MatPaginator;

  dataSource = new MatTableDataSource<VpnMetadata>();
  displayedColumns = ['email', 'actionTable'];
  mobile = false;
  tablet = false;
  totalFiles = 0;
  pageSize = 10;
  page = 0;

  paths: string[] = [];
  breadcrumb: string = '';
  showUpload = true;

  searchWord = '';

  constructor(private translate: TranslateService,
              private zimbraService: ZimbraService,
              private spinnerService: SpinnerService,
              private userService: UserService,
              private notification: NotificationService,
              private toastyService: ToastyService,
              private router: Router) { }

  ngAfterViewInit(): void {
    this.translate.get('vpn').subscribe(i=>{
      this.breadcrumb = i.title;
    });
    this.getUsersVpnHaveAccess(this.page, this.pageSize);
  }
  // getUsersVpn(){
  //   this.spinnerService.showSpinner(true);
  //   this.zimbraService.getListVpn().subscribe(data =>{
  //     this.dataSource.data = data;
  //     this.matPaginator.length = data.length;
  //     this.spinnerService.hideSpinner();
  //   },error => {
  //     console.log('error: ', error);
  //     this.spinnerService.hideSpinner();
  //   });
  // }
  getUsersVpnHaveAccess(page, pageSize, searchWorld?){
    this.spinnerService.showSpinner(true);
    this.zimbraService.getListVpnHaveAccess(page, pageSize, searchWorld).subscribe(data =>{
      this.dataSource.data = data.data;
      this.matPaginator.length = data.total;
      this.totalFiles = data.total;
      this.spinnerService.hideSpinner();
    },error => {
      console.log('error: ', error);
      this.spinnerService.hideSpinner();
    })
  }

  onClickPath(pathPosition: number) {
    if(pathPosition === 0) {
      this.paths = [];
      this.matPaginator.pageIndex = 0;
      this.getUsersVpnHaveAccess(this.page, this.pageSize);
    }
  }

  onScroll(event) {
    const showUpload = event.target.scrollTop < 20;
    if (this.showUpload === showUpload) {
      return;
    }
    const mainComponentHeight = event.target.parentElement.parentElement.parentElement.offsetHeight;
    const listComponentHeight = event.target.scrollHeight;
    if (showUpload || (!showUpload && listComponentHeight >= mainComponentHeight - 20)) {
      this.showUpload = showUpload;
    }
  }

  onResize(event) {
    // this.hideColumns(event.target.innerWidth);
  }

  hideColumns(width) {
    this.mobile = false;
    this.tablet = false;
    this.displayedColumns = ['name', 'modified', 'owner', 'writePermission',  'actionTable'];
    if (width >= 768 && width <= 1024) {
      this.tablet = true;
    } else if (width < 768) {
      this.mobile = true;
    }
  }
  onChangePageListener (page) {
    this.getUsersVpnHaveAccess(page.pageIndex , page.pageSize);

  }

  concessaoVpn(email, status){
    this.spinnerService.showSpinner(true);
    this.zimbraService.alterVpnAccess(email, status).subscribe(data => {
      if(status === '1')
        this.notification.notifySuccess(this.toastyService, 'VPN autorizada com sucesso',`O usuário ${email} recebeu acesso a VPN`);
      else
        this.notification.notifyInfo(this.toastyService, 'VPN negada com sucesso',`O usuário ${email} teve a VPN negada`);
      this.router.navigate(['/vpn']);
      this.spinnerService.hideSpinner();
      this.getUsersVpnHaveAccess(1, 10);
    }, error => {
      if(status === '1')
        this.notification.notifySuccess(this.toastyService, 'Erro ao autorizar VPN',`Erro ao autorizar VPN para o usuário ${email}`);
      else
        this.notification.notifySuccess(this.toastyService, 'Erro ao negar VPN',`Erro ao negar VPN para o usuário ${email}`);
      this.spinnerService.hideSpinner();
    })

  }

  searchOn() {
    this.getUsersVpnHaveAccess(this.page, this.pageSize, this.searchWord);
  }
}

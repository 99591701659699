import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ClickOutsideModule } from 'ng-click-outside';
import { MomentModule } from 'angular2-moment';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TagInputModule } from 'ngx-chips';
import { SelectModule } from 'ng-select';
import {HomeRoutingModule} from './home-routing.module';
import {SharedModule} from '../../shared/shared.module';
import {HomeComponent} from './home.component';
import {MainComponent} from './main/main.component';
import {HomeService} from './home.service';
import {FirstAccessService} from '../login/first-access/first-access.service';
import {MatTableModule} from '@angular/material/table';
import {FileUploadModule} from 'ng2-file-upload';
import {DragAndDropModule} from 'angular-draggable-droppable';
import {ContextMenuModule} from 'ngx-contextmenu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {DragToSelectModule} from 'ngx-drag-to-select';
import { ChangeDataService } from './main/change-data/change-data.service';
import {ChangePasswordService} from './main/change-password/change-password.service';
import { ChangeDataComponent } from './main/change-data/change-data.component';
import {ChangePasswordComponent} from './main/change-password/change-password.component';
import { VpnComponent } from './vpn/vpn.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {ZimbraService} from './vpn/zimbra.service';
import {RestoreComponent} from './restore/restore.component';
import {RestoreService} from './restore/restore.service';
import {SelectRestoreComponent} from './restore/select-restore/select-restore.component';
import {BackupSchedulerComponent} from './backup-scheduler/backup-scheduler.component';
import {BackupService} from './backup-scheduler/backup.service';
import {ChartsModule} from 'ng2-charts';
import { NewUserComponent } from './account/new-user/new-user.component';
import {MatStepperModule} from '@angular/material/stepper';
import { TextMaskModule } from 'angular2-text-mask';
import {AccountService} from './account/account.service';
import {AccountComponent} from './account/account.component';
import {ApiAdminService} from '../../shared/services/api-admin.service';
import {ConfirmComponent} from './confirm-modal/confirm-modal.component';
import {VpnAccessComponent} from './vpn-access/vpn-access.component';
import {NgApexchartsModule} from "ng-apexcharts";
// import { NewUserOfficeComponent } from './account/new-user-office/new-user-office.component';
import {SelectDropDownModule} from "ngx-select-dropdown";
import {NewUserOfficeComponent} from './account/new-user-office/new-user-office.component';
import {ConfirmPurgeComponent} from './confirm-modal-purge-user/confirm-modal-purge-user.component';
import {ListUserOfficeComponent} from './account/list-user-office/list-user-office.component';

@NgModule({
    imports: [
        CommonModule,
        HomeRoutingModule,
        SharedModule,
        ClickOutsideModule,
        TranslateModule,
        FileUploadModule,
        FormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatCheckboxModule,
        PdfViewerModule,
        MomentModule,
        TagInputModule,
        SelectModule,
        DragAndDropModule,
        MatSidenavModule,
        ChartsModule,
        ContextMenuModule.forRoot({
            useBootstrap4: true,
            autoFocus: true,
        }),
        DragToSelectModule,
        MatStepperModule,
        TextMaskModule,
        ReactiveFormsModule,
        NgApexchartsModule,
        SelectDropDownModule
    ],
  declarations: [
    HomeComponent,
    MainComponent,
    ChangeDataComponent,
    ChangePasswordComponent,
    VpnComponent,
    BreadcrumbComponent,
    RestoreComponent,
    SelectRestoreComponent,
    BackupSchedulerComponent,
    NewUserComponent,
    AccountComponent,
    ConfirmComponent,
    VpnAccessComponent,
    NewUserOfficeComponent,
    ConfirmPurgeComponent,
    ListUserOfficeComponent
  ],
  providers: [
    ChangeDataService,
    ChangePasswordService,
    HomeService,
    ZimbraService,
    ApiAdminService,
    FirstAccessService,
    RestoreService,
    BackupService,
    AccountService
    ],
    bootstrap: [HomeComponent]
})
export class HomeModule { }

import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApiZimbraService} from "../../../shared/services/api-zimbra.service";

@Injectable()
export class BackupService {

  static PATH_BACKUP = "/api/backup";

  constructor(private apiZimbraService: ApiZimbraService) {
  }

  getConfig(): Observable<any> {
    return this.apiZimbraService.get(BackupService.PATH_BACKUP+"/schedule");
  }

  saveConfig(schedule): Observable<any> {
    return this.apiZimbraService.post(BackupService.PATH_BACKUP+"/schedule", schedule);
  }

  executeBackup(): Observable<any> {
    return this.apiZimbraService.post(BackupService.PATH_BACKUP+"/now");
  }

  getProgress(): Observable<any>{
    return this.apiZimbraService.get(BackupService.PATH_BACKUP+"/progress");
  }

}

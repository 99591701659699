import { Input, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastyService } from 'ng2-toasty';
import { Router } from '@angular/router';

import { AuthService } from '../../../../shared/services/auth.service';
import { NotificationService } from '../../../../shared/services/notification.service';
import { ModalBasicComponent } from '../../../../shared/modal-basic/modal-basic.component';
import { ChangePasswordService } from './change-password.service';
import { SpinnerService } from '../../../../shared/spinner/spinner.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss',
  '../../../../../../node_modules/ng2-toasty/style-bootstrap.css',
  '../../../../../../node_modules/ng2-toasty/style-default.css',
  '../../../../../../node_modules/ng2-toasty/style-material.css'],
  encapsulation: ViewEncapsulation.None
})
export class ChangePasswordComponent {

  @ViewChild('modalChangePassword') modalBasic: ModalBasicComponent;

  lang: string;

  /**
   * inputs values
   */
  oldPasswordValue: string = null;
  newPasswordValue: string = null;
  confirmNewPasswordValue: string = null;

  /**
   *  internationalization variable
   */
  enterOldPasswordFailed: string;
  enterNewPasswordFailed: string;
  enterConfirmNewPasswordFailed: string;
  enterPasswordIsEmpty: string;
  titleError: string;
  requestSend: string;
  changedPassword: string;
  titleInvalid: string;
  enterValidPassword: string;
  passwordInvalidSize: string;
  passwordNotStrong: string;
  titleSuccess: string;
  passwordChange: string;
  invalidData: string;
  tryAgainFewMoments: string;
  titleServerError: string;

  idx: string;
  idxMessage: string;
  classMsgPassword: string;

  constructor(private translate: TranslateService,
              private notification: NotificationService,
              private toastyService: ToastyService,
              private router: Router,
              private authService: AuthService,
              public spinnerService: SpinnerService,
              private changePasswordService: ChangePasswordService) {
  }

  public show(): void {
    this.oldPasswordValue = '';
    this.newPasswordValue = '';
    this.confirmNewPasswordValue = '';
    this.idx = '';
    this.idxMessage = '';
    this.classMsgPassword = '';
    this.lang = this.translate.currentLang;
    this.getInternationalizationVariable();
    this.modalBasic.show();
  }

  public hide(): void {
    this.modalBasic.hide();
  }

  /**
   * get internationalization from i18n
   */
  getInternationalizationVariable(): void {
    this.translate.get('change-password')
    .subscribe(i => {
      this.enterOldPasswordFailed = i.error_enter_oldpassword;
      this.enterNewPasswordFailed = i.error_enter_newpassword;
      this.enterConfirmNewPasswordFailed = i.error_enter_confirm_newpassword;
      this.enterPasswordIsEmpty = i.error_password_empty;
      this.titleError = i.error_missing_password;
      this.titleInvalid = i.error_invalid_password;
      this.requestSend = i.request_send;
      this.enterValidPassword = i.error_valid_password;
      this.passwordInvalidSize = i.error_password_size;
      this.passwordNotStrong = i.error_password_strong;
      this.titleSuccess = i.password_change;
      this.passwordChange = i.success_password;
      this.invalidData = i.error_invalid_data;
      this.titleServerError = i.error_server;
      this.tryAgainFewMoments = i.error_try_again_few_moments;
    });
  }

  /**
   * When the inputs are empty or invalid
   */
  notifyErrorWhenPasswordEmpty() {
    if (this.isEmpty(this.oldPasswordValue) && this.isEmpty(this.newPasswordValue) && this.isEmpty(this.confirmNewPasswordValue)) {
      this.notification.notifyError(this.toastyService, this.titleError, this.enterPasswordIsEmpty);
    } else if (this.isEmpty(this.oldPasswordValue)) {
      this.notification.notifyError(this.toastyService, this.titleError, this.enterOldPasswordFailed);
    } else if (this.isEmpty(this.newPasswordValue)) {
      this.notification.notifyError(this.toastyService, this.titleError, this.enterNewPasswordFailed);
    } else if (this.isEmpty(this.confirmNewPasswordValue)) {
      this.notification.notifyError(this.toastyService, this.titleError, this.enterConfirmNewPasswordFailed);
    } else if (this.newPasswordValue.length < 8) {
      this.notification.notifyError(this.toastyService, this.titleInvalid, this.passwordInvalidSize);
    }
  }
  isEmpty(str) {
    return (!str || 0 === str.length);
  }

  doChangePassword() {
    if (this.newPasswordValue !== this.confirmNewPasswordValue) {
      this.notification.notifyError(this.toastyService, this.titleInvalid, this.enterValidPassword);
      return;
    } else if (this.idx !== 'strong') {
      this.notification.notifyError(this.toastyService, this.titleInvalid, this.passwordNotStrong);
      return;
    }

    this.spinnerService.showSpinner(true);

    this.changePasswordService.updatePassword(this.oldPasswordValue, this.newPasswordValue).subscribe(
      data => {
        this.spinnerService.showSpinner(false);
        this.notification.notifySuccess(this.toastyService, this.titleSuccess, this.passwordChange);
        setTimeout ( () => {
          this.spinnerService.hideSpinner();
          this.router.navigate(['login']);
          this.authService.logout();
        }, 3500);
      },
      err => {
        this.spinnerService.hideSpinner();

        if (err.error.message === this.changePasswordService.ERROR_BAD_LOGIN) {
          this.notification.notifyError(this.toastyService, this.titleInvalid, this.invalidData);
        } else {
          this.notification.notifyError(this.toastyService, this.titleServerError, this.tryAgainFewMoments);
        }
      }
    );
  }

  watchPass(event: any) {
    this.getClassStrength(this.measureStrength(this.newPasswordValue));
  }

  private measureStrength(p: string) {
    let _force = 0;
    const _regex = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g;
    const _lowerLetters = /[a-z]+/.test(p);
    const _upperLetters = /[A-Z]+/.test(p);
    const _numbers = /[0-9]+/.test(p);
    const _symbols = _regex.test(p);
    const _flags = [_lowerLetters, _upperLetters, _numbers, _symbols];
    let _passedMatches = 0;
    if (p.length <= 7) {
      this.idx = 'weak';
      this.classMsgPassword = 'weak-m';
      if (this.lang === 'pt') {
        this.idxMessage = 'Senha fraca, tente outra!';
      } else if (this.lang === 'en') {
        this.idxMessage = 'Weak password, try another one!';
      }
      _force = 0;
    } else {
      for (let _i = 0, _flags_1 = _flags; _i < _flags_1.length; _i++) {
        const _flag = _flags_1[_i];
        _passedMatches += _flag === true ? 1 : 0;
      }
      _force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
      _force += _passedMatches * 10;
      _force = (p.length <= 6) ? Math.min(_force, 10) : _force;
      _force = (_passedMatches === 1) ? Math.min(_force, 10) : _force;
      _force = (_passedMatches === 2) ? Math.min(_force, 20) : _force;
      _force = (_passedMatches === 3) ? Math.min(_force, 30) : _force;
      _force = (_passedMatches === 4) ? Math.min(_force, 40) : _force;
      return _force;
    }
  }

  private getClassStrength(s: number) {
    if (s <= 10) {
      this.idx = 'weak';
      this.classMsgPassword = 'weak-m';
      if (this.lang === 'pt') {
        this.idxMessage = 'Senha fraca, tente outra!';
      } else if (this.lang === 'en') {
        this.idxMessage = 'Weak password, try another one!';
      }
    } else if (s <= 20) {
      this.idx = 'medium';
      this.classMsgPassword = 'medium-m';
      if (this.lang === 'pt') {
        this.idxMessage = 'Senha média, continue tentando!';
      } else if (this.lang === 'en') {
        this.idxMessage = 'Average password, keep trying!';
      }
    } else if (s <= 30) {
      this.idx = 'medium';
      this.classMsgPassword = 'medium-m';
      if (this.lang === 'pt') {
        this.idxMessage = 'Senha média, continue tentando!';
      } else if (this.lang === 'en') {
        this.idxMessage = 'Average password, keep trying!';
      }
    } else if (s <= 40) {
      this.idx = 'strong';
      this.classMsgPassword = 'strong-m';
      if (this.lang === 'pt') {
        this.idxMessage = 'Senha forte, muito bem!';
      } else if (this.lang === 'en') {
        this.idxMessage = 'Password strong, very well!';
      }
    }
  }
}

export class FileView {
  content: string = "";
  deleted: number = 0;
  lastUpdate: number = 0;
  purged: number = 0;
  type: string = "";
  encryptionKey: string = "";
  publicFile: boolean = false;
  publicKey?: string = "";
  uploadId: number = 0;
  size: number = 0;
  encrypted: boolean = false;
  folder: boolean = false;
  displayName: string = "";
  selected: boolean = false;
  displayDate: string = "";
  writePermission: boolean = false;
}

import { Component, OnInit, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PopupService } from '@ng-bootstrap/ng-bootstrap/util/popup';
import { TranslateService } from '@ngx-translate/core';
import { ToastyService } from 'ng2-toasty';

import { PreferenceService } from '../../../shared/services/preference.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { AuthService } from '../../../shared/services/auth.service';
import { FirstAccessService } from './first-access.service';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import {ResendEmailComponent} from "../forgot/resend-email/resend-email.component";

@Component({
  selector: 'app-first-access',
  templateUrl: './first-access.component.html',
  styleUrls: ['../login.component.scss',
  '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
  '../../../../../node_modules/ng2-toasty/style-default.css',
  '../../../../../node_modules/ng2-toasty/style-material.css'],
  encapsulation: ViewEncapsulation.None
})
export class FirstAccessComponent implements OnInit {

  @ViewChild('modalResendEmail') modalResendEmail: ResendEmailComponent;

  public lang: string;

  /**
   *  internationalization variable
   */
  enterEmailFailed: string;
  enterEmailInvalid: string;
  enterUserFailed: string;
  enterCredentialIsEmpty: string;
  titleMissing: string;
  titleInvalid: string;
  titleError: string;
  tryAgainFewMoments: string;
  requestSend: string;
  emailSend: string;
  titleSuccess: string;
  invalidData: string;

  constructor(private translate: TranslateService,
    private service: AuthService,
    private preference: PreferenceService,
    private router: Router,
    private notification: NotificationService,
    private forgotService: FirstAccessService,
    public spinnerService: SpinnerService,
    private toastyService: ToastyService) {}

  ngOnInit() {
    this.checkSessionSaved();
    this.initLanguage();
  }

  /**
   * get language set in localStorage
   */
   initLanguage(): void {
     if (this.preference.getPreference()) {
       const lang = this.preference.getPreference();
       this.useLanguage(lang.lang);
     }
   }
   /**
    * If session salved, go home. Else stay in login
    */
   checkSessionSaved() {
     if (this.service.isSignedIn()) {
        this.router.navigate(['home']);
     }
   }

  /**
    * Change language
    * @param language
    */
   useLanguage(language: string) {
     this.translate.use(language);
     this.lang = language;
   }

   /**
    * When the inputs are empty
    * @param username input value username
    * @param email input value email
    */
   notifyErrorWhenCredentialEmpty(email: String) {
     this.getInternationalizationLoginVariable();
     if (this.isEmpty(email)) {
       this.notification.notifyError(this.toastyService, this.titleMissing, this.enterCredentialIsEmpty);
     }  else if (this.isEmpty(email)) {
       this.notification.notifyError(this.toastyService, this.titleMissing, this.enterEmailFailed);
     } else {
       this.notification.notifyError(this.toastyService, this.titleInvalid, this.enterEmailInvalid);
     }
   }

   /**
    * get internationalization from i18n
    */
   getInternationalizationLoginVariable(): void {
     this.translate.get('login')
     .subscribe(i => {
       this.enterEmailFailed = i.error_enter_email;
       this.enterEmailInvalid = i.error_invalid_email;
       this.enterUserFailed = i.error_enter_username;
       this.enterCredentialIsEmpty = i.error_credential_empty;
       this.titleMissing = i.error_missing_data;
       this.titleInvalid = i.error_invalid_data;
       this.tryAgainFewMoments = i.error_try_again_few_moments;
       this.titleError = i.error_server;
       this.invalidData = i.error_invalid_forgot;
     });
   }

   /**
    * get internationalization from i18n
    */
   getInternationalizationForgotVariable(): void {
     this.translate.get('forgot')
     .subscribe(i => {
       this.requestSend = i.request_send;
       this.emailSend = i.email_send;
     });
   }

   isEmpty(str) {
     return (!str || 0 === str.length);
   }

   doRequest(email) {
     this.spinnerService.showSpinner(true);
     this.forgotService.recoverPassword(email, email).subscribe(
       data => {
         this.getInternationalizationForgotVariable();
         this.notification.notifySuccess(this.toastyService, this.requestSend, this.emailSend);
         this.spinnerService.hideSpinner();
         this.modalResendEmail.show();
       },
       err => {
         this.getInternationalizationLoginVariable();
         this.spinnerService.hideSpinner();
         if (err.message === this.forgotService.ERROR_INVALID_DATA) {
           this.notification.notifyError(this.toastyService, this.titleInvalid, this.invalidData);
         } else {
           this.notification.notifyError(this.toastyService, this.titleError, this.tryAgainFewMoments);
         }
       }
     );
   }

}

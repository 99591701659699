<app-modal-basic #modalChangeData>
  <div class="app-modal-header">
    <h4 class="modal-title">{{'change-data.title' | translate}}</h4>
    <button type="button" class="close basic-close" (click)="modalChangeData.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <form #changeDataForm="ngForm">
      <label class="small">{{'general.name' | translate}}</label>
      <div class="input-group">
        <input type="text" class="form-control" name="userName" required placeholder="{{'change-data.username' | translate}}" #userName="ngModel"
        [(ngModel)]="userNameValue">
        <span class="md-line"></span>
      </div>
      <label class="small">{{'change-data.e-mail' | translate}}</label>
      <div class="input-group">
          <input type="email" class="form-control" name="email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          placeholder="{{'change-data.email' | translate}}" #email="ngModel" [(ngModel)]="emailValue">
        <span class="md-line"></span>
      </div>
    </form>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-danger waves-effect " (click)="modalChangeData.hide()">{{'general.cancel' | translate}}</button>
    <button type="button" class="btn btn-primary waves-effect waves-light" 
            (click)="(changeDataForm.valid) ? doChangeData() : notifyErrorWhenDataEmpty()">
            {{'general.confirm' | translate}}
    </button>
  </div>
</app-modal-basic>
<ng2-toasty></ng2-toasty>

import {Component, OnInit, ViewChild} from '@angular/core';
import {UserModel} from './user.model';
import {NotificationService} from '../../../../shared/services/notification.service';
import {ToastyService} from 'ng2-toasty';
import {TranslateService} from '@ngx-translate/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import {SpinnerService} from '../../../../shared/spinner/spinner.service';
import { MatStepper } from '@angular/material/stepper';
import {AccountService} from '../account.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiEbcorpService} from '../../../../shared/services/api-ebcorp.service';


@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit{

  maskCPF = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  user: UserModel;
  emails: string[] = ['email1@email.com', 'email2@email.com'];
  id;

  @ViewChild('stepper') stepper: MatStepper;
  accountForm1: FormGroup;
  accountForm2: FormGroup;


  enterPasswordFailed: string;
  enterPasswordConfirmFailed: string;
  enterCredentialIsEmpty: string;
  enterValidPassword: string;
  titleMissing: string;
  titleInvalid: string;
  titleError: string;
  tryAgainFewMoments: string;
  titleSuccess: string;
  passwordInvalidSize: string;
  passwordChange: string;
  passwordNotChange: string;
  passwordNotStrong: string;
  invalidCpf: string;
  idx: string;
  idxMessage: string;
  classMsgPassword: string;
  lang: string;
  dropdownOptions: [] = [];

  config = {
    displayKey: 'sigla',
    search: true,
    height: '20rem',
    placeholder: 'Selecione',
    customComparator: () => {},
    limitTo: 0,
    moreText: 'more',
    noResultsFound: 'OM não encontrada!',
    searchPlaceholder: 'Pesquise',
    searchOnKey: 'sigla'
  };

  constructor(
    private _formBuilder: FormBuilder,
    private translate: TranslateService,
    private notification: NotificationService,
    private toastyService: ToastyService,
    private spinnerService: SpinnerService,
    private accountService: AccountService,
    private router: Router,
    private routerParam: ActivatedRoute,
    private apiEbcorpService: ApiEbcorpService,
    private toastService: ToastyService
  ) {}

  ngOnInit(): void {
    this.id = this.routerParam.snapshot.params['id'];
    this.user = new UserModel();
    // this.getOms();
    this.accountForm1 = this._formBuilder.group({
      nomeCompleto: ['', Validators.required],
      postoMilitar: ['', Validators.required],
      nomeGuerra: ['', Validators.required],
      cpf: ['', Validators.required],
      // om: ['', Validators.required],
      // codigoOm: ['', Validators.required],
    });
    this.accountForm2 = this._formBuilder.group({
      email: ['', Validators.email],
      password: ['', Validators.required],
      confirmPassword: ['', [Validators.required, (control => NewUserComponent.confirmPasswordControl(control, this.accountForm2))]]
    });
    if (!this.isNewUser()) {
      // TODO: get User to update
    }
  }


  next() {
  if (!this.isValidCPF(this.user.cpf) || !this.user) {
        this.toastService.error('CPF Invalido');
        return;
  }
    // this.spinnerService.showSpinner(true);
    // this.accountService.getEmails(this.user.postoMilitar, this.user.nomeCompleto).subscribe(data => {
    //   this.emails = data.mails;
    //   this.spinnerService.hideSpinner();
    // }, error => {
    //   this.spinnerService.hideSpinner();
    //   this.stepper.reset();
    //   console.log('error: ', error);
    // });
  }

  back() {
  }

  finish() {
    const password = this.accountForm2.get('password').value;
    const confirmPassword = this.accountForm2.get('confirmPassword').value;
    if (password !== confirmPassword) {
      this.notification.notifyError(this.toastyService, this.titleInvalid, this.enterValidPassword);
    } else if (this.idx !== 'strong') {
      this.notification.notifyError(this.toastyService, this.titleInvalid, this.passwordNotStrong);
      return;
    }else if (!this.isValidCPF(this.user.cpf) || !this.user){
      this.toastService.error('CPF Invalido');
    }
    else if (this.stepper !== undefined) {
      this.stepper.next();
    }
  }

  doCreate() {
    this.spinnerService.showSpinner(true);
    this.accountService.createUser(this.user, this.user.email, this.user.password).subscribe(data => {
      this.spinnerService.hideSpinner();
      this.router.navigate(['']);
    }, error => {
      console.log('error: ', error);
      this.spinnerService.hideSpinner();
      this.toastyService.error('Erro, Tente Novamente');
    });
  }

  isNewUser(): boolean {
    return  !this.id;
  }

  isEmpty(str) {
    return (!str || 0 === str.length);
  }

  static confirmPasswordControl(control, form: FormGroup) {
    if (control.value &&
      form && form.controls &&
      form.controls['password'].value !== null &&
      form.controls['password'].value === control.value) {
      return null;
    }
    return { 'mismatch': true };
  }

  getInternationalizationLoginVariable(): void {
    this.translate.get('login')
      .subscribe(i => {
        this.enterPasswordFailed = i.error_enter_password;
        this.enterPasswordConfirmFailed = i.error_enter_passwordConfirm;
        this.enterCredentialIsEmpty = i.error_credential_empty;
        this.titleMissing = i.error_missing_data;
        this.titleError = i.error_server;
        this.tryAgainFewMoments = i.error_try_again_few_moments;
        this.titleInvalid = i.error_invalid_data;
        this.passwordInvalidSize = i.error_password_size;
        this.invalidCpf = i.invalid_cpf;
      });
  }

  notifyErrorWhenCredentialEmpty(passwordConfirm: String) {
    const password = this.accountForm2.get('password').value;
    this.getInternationalizationLoginVariable();
    if (this.isEmpty(password) && this.isEmpty(passwordConfirm)) {
      this.notification.notifyError(this.toastyService, this.titleMissing, this.enterCredentialIsEmpty);
    } else if (this.isEmpty(password)) {
      this.notification.notifyError(this.toastyService, this.titleMissing, this.enterPasswordFailed);
    } else if (this.isEmpty(passwordConfirm)) {
      this.notification.notifyError(this.toastyService, this.titleMissing, this.enterPasswordConfirmFailed);
    } else if (password.length < 8) {
      this.notification.notifyError(this.toastyService,  this.titleInvalid, this.passwordInvalidSize);
    }
  }

  watchPass(event: any) {
    this.getClassStrength(this.measureStrength(this.user.password));
  }

  private getClassStrength(s: number) {
    if (s <= 10) {
      this.idx = 'weak';
      this.classMsgPassword = 'weak-m';
      if (this.lang === 'pt') {
        this.idxMessage = 'Senha fraca, tente outra!';
      } else if (this.lang === 'en') {
        this.idxMessage = 'Weak password, try another one!';
      }
    } else if (s <= 20) {
      this.idx = 'medium';
      this.classMsgPassword = 'medium-m';
      if (this.lang === 'pt') {
        this.idxMessage = 'Senha média, continue tentando!';
      } else if (this.lang === 'en') {
        this.idxMessage = 'Average password, keep trying!';
      }
    } else if (s <= 30) {
      this.idx = 'medium';
      this.classMsgPassword = 'medium-m';
      if (this.lang === 'pt') {
        this.idxMessage = 'Senha média, continue tentando!';
      } else if (this.lang === 'en') {
        this.idxMessage = 'Average password, keep trying!';
      }
    } else if (s <= 40) {
      this.idx = 'strong';
      this.classMsgPassword = 'strong-m';
      if (this.lang === 'pt') {
        this.idxMessage = 'Senha forte, muito bem!';
      } else if (this.lang === 'en') {
        this.idxMessage = 'Password strong, very well!';
      }
    }
  }

  private measureStrength(p: string) {
    let _force = 0;
    const _regex = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g;
    const _lowerLetters = /[a-z]+/.test(p);
    const _upperLetters = /[A-Z]+/.test(p);
    const _numbers = /[0-9]+/.test(p);
    const _symbols = _regex.test(p);
    const _flags = [_lowerLetters, _upperLetters, _numbers, _symbols];
    let _passedMatches = 0;
    if (p.length <= 7) {
      this.idx = 'weak';
      this.classMsgPassword = 'weak-m';
      if (this.lang === 'pt') {
        this.idxMessage = 'Senha fraca, tente outra!';
      } else if (this.lang === 'en') {
        this.idxMessage = 'Weak password, try another one!';
      }
      _force = 0;
    } else {
      for (let _i = 0, _flags_1 = _flags; _i < _flags_1.length; _i++) {
        const _flag = _flags_1[_i];
        _passedMatches += _flag === true ? 1 : 0;
      }
      _force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
      _force += _passedMatches * 10;
      _force = (p.length <= 6) ? Math.min(_force, 10) : _force;
      _force = (_passedMatches === 1) ? Math.min(_force, 10) : _force;
      _force = (_passedMatches === 2) ? Math.min(_force, 20) : _force;
      _force = (_passedMatches === 3) ? Math.min(_force, 30) : _force;
      _force = (_passedMatches === 4) ? Math.min(_force, 40) : _force;
      return _force;
    }
  }

  isValidCPF(cpf: string) {
    const cleanCPF = cpf.replace(/\.|-|\s/g, '');
    const firstNineDigits = cleanCPF.substring(0, 9);
    const checker = cleanCPF.substring(9, 11);

    if (cleanCPF.length !== 11) {
      return false;
    }

    // Checking if all digits are equal
    let i;
    for (i = 0; i < 10; i++) {
      if ('' + firstNineDigits + checker === Array(12).join(i)) {
        return false;
      }
    }

    const checker1 = this.calcChecker1(firstNineDigits);
    const checker2 = this.calcChecker2('' + firstNineDigits + checker1);

    if (checker.toString() === checker1.toString() + checker2.toString()) {
      return true;
    } else {
      return false;
    }
  }

  private calcChecker1(firstNineDigits) {
    let sum = null;

    for (let j = 0; j < 9; ++j) {
      sum += firstNineDigits.toString().charAt(j) * (10 - j);
    }

    const lastSumChecker1 = sum % 11;
    const checker1 = lastSumChecker1 < 2 ? 0 : 11 - lastSumChecker1;

    return checker1;
  }

  private calcChecker2(cpfWithChecker1) {
    let sum = null;

    for (let k = 0; k < 10; ++k) {
      sum += cpfWithChecker1.toString().charAt(k) * (11 - k);
    }

    const lastSumChecker2 = sum % 11;
    const checker2 = lastSumChecker2 < 2 ? 0 : 11 - lastSumChecker2;

    return checker2;
  }

  loadAccount() {
    this.accountService.getAccountById(this.id).subscribe(
      data => {
        this.parseAccount(data);
      },
      err => {
        this.notification.notifyError(this.toastyService, this.titleError, this.tryAgainFewMoments);
        this.router.navigate(['user', 'list']);
      }
    );
  }

  private parseAccount(data: any) {
    this.user.cpf = data.cpf;
    // TODO: set all attr
    const companyId = data.userHasGroups[0].groupId.toString();
    this.getCompany(companyId);
  }

  private  getCompany(id) {
      this.accountService.getCompanyById(id).subscribe(
        data => {
          this.user.cpf = data.code;
        },
        err => {
          this.notification.notifyError(this.toastyService, this.titleError, this.tryAgainFewMoments);
          this.router.navigate(['account']);
        }
      );
    }

  getOms() {
    this.apiEbcorpService.get('/om').subscribe(data => {
      this.dropdownOptions = data;
    }, () => {
      this.toastService.error('Erro ao recuperar OM');
    });
  }

  selectionChanged(event: any) {
    const om = event.value;
    this.user.om = om.sigla;
    this.user.codigoOm = om.codom;
  }
  isfull(cpf) {
    const cleanCPF: string = cpf.replace(/\D+/g, '');
    return cleanCPF.length >= 11;
  }
}

import { Component, ViewChild, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastyService } from 'ng2-toasty';
import {ModalBasicComponent} from '../../../shared/modal-basic/modal-basic.component';
import {AccountService} from '../account/account.service';
import {SpinnerService} from '../../../shared/spinner/spinner.service';

@Component({
  selector: 'app-confirm-modal-purge-user',
  templateUrl: './confirm-modal-purge-user.component.html',
  styleUrls: ['./confirm-modal-purge-user.component.scss',
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'],
  encapsulation: ViewEncapsulation.None
})

export class ConfirmPurgeComponent {

  @ViewChild('modalconfirmPurgeUser') modalBasic: ModalBasicComponent;
  @Output() removedAccountsEvent = new EventEmitter<string>();

  accounts = [];

  // internationalization variable

  requestSend: string;
  removedAccounts: string;
  removedSomeAccounts: string;
  tryAgainFewMoments: string;
  titleServerError: string;
  titleWithParents: string;
  removeWithParents: string;
  id;
  email: string;
  block: boolean;
  fullname: string;
  isOfficeAccount: boolean

  constructor(
    private translate: TranslateService,
    private toastyService: ToastyService,
    public spinnerService: SpinnerService,
    private accountService: AccountService) { }

  public show(id: number, fullname:string, email: string, isOfficeAccount?): void {
    this.isOfficeAccount = !!isOfficeAccount
    this.id = id;
    this.fullname = fullname;
    this.email = email;
    this.getInternationalizationVariable();
    this.modalBasic.show();
  }

  public hide(): void {
    this.modalBasic.hide();
  }

  // get internationalization from i18n
  getInternationalizationVariable(): void {
    this.translate.get('remove-account')
      .subscribe(i => {
        this.requestSend = i.request_send;
        this.removedAccounts = i.removed_accounts;
        this.removedSomeAccounts = i.removed_some_accounts;
        this.titleServerError = i.error_server;
        this.tryAgainFewMoments = i.error_try_again_few_moments;
        this.titleWithParents = i.error_title_account_with_parents;
        this.removeWithParents = i.error_remove_with_parents;
      });
  }

  onConfirm() {
    this.spinnerService.showSpinner(true);
      this.accountService.purgeUser(this.email).subscribe(value => {
        this.spinnerService.hideSpinner()
        this.toastyService.success("Usuário Excluido")
        this.modalBasic.hide()
       this.removedAccountsEvent.emit()
      }, error => {
        console.log(error)
        this.spinnerService.hideSpinner()
        this.toastyService.error("Erro ao excluir usuário")
        this.modalBasic.hide()
        this.removedAccountsEvent.emit()
      })
    }

  onConfirmOfficeUser() {
    console.log("email:", this.email);
    this.spinnerService.showSpinner(true);
    this.accountService.deleteUserOffice(this.email).subscribe(value => {
      this.spinnerService.hideSpinner()
      this.toastyService.success("Excluido")
      this.modalBasic.hide()
      this.removedAccountsEvent.emit()
    }, error => {
      console.log(error)
      this.spinnerService.hideSpinner()
      this.toastyService.error("Erro ao excluir")
      this.modalBasic.hide()
      this.removedAccountsEvent.emit()
    })
  }
}

<div id="pcoded" class="pcoded"
     [attr.nav-type]="navType"
     [attr.theme-layout]="themeLayout"
     [attr.vertical-placement]="verticalPlacement"
     [attr.vertical-layout]="verticalLayout"
     [attr.pcoded-device-type]="pcodedDeviceType"
     [attr.vertical-nav-type]="verticalNavType"
     [attr.vertical-effect]="verticalEffect"
     [attr.vnavigation-view]="vnavigationView"
     [attr.fream-type]="freamType"
     [attr.sidebar-img]="sidebarImg"
     [attr.sidebar-img-type]="sidebarImgType"
     [attr.layout-type]="layoutType"
     (window:resize)="onResize($event)"
  >
  <div class="pcoded-overlay-box"></div>
  <div class="pcoded-container navbar-wrapper">
    <nav class="navbar header-navbar pcoded-header" [attr.pcoded-header-position]="pcodedHeaderPosition">
      <div class="navbar-wrapper">
        <div class="navbar-logo">
          <a class="mobile-menu" id="mobile-collapse" href="javascript:" *ngIf="mobile" (click)="toggleOpened()" (clickOutside)="onClickedOutsideSidebar($event)">
            <i class="icon-menu"></i>
          </a>
          <a [routerLink]="['/home']">
            <img class="img-fluid img-logo" src="assets/images/logo.png" alt="Cloud Backup" />
          </a>
          <a href="javascript:" class="mobile-options" *ngIf="mobile" (click)="toggleHeaderNavRight()">
            <i class="icon-more"></i>
          </a>
        </div>
        <div class="navbar-container container-fluid">
          <ul class="nav-left">
<!--            <li class="header-search" *ngIf="!mobile">-->
<!--              <div id="main-search" class="main-search morphsearch-search">-->
<!--                <div id="input-search" class="input-group">-->
<!--                    <span class="search-btn" (click)="searchOn()"><i class="icon-search"></i></span>-->
<!--                     <input id="searchBar" (keyup.enter)="onKey()" [(ngModel)]="searchWord" ngClass="{{ searchWidth==0 ? 'hidden-input':''}}"  [style.width]="searchWidthString"/>-->
<!--                      <span class="search-close input-group-addon" (click)="searchOff()"><i class="icon-close"></i></span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </li>-->
          </ul>
          <ul [@mobileHeaderNavRight]="navRight" [ngClass]="navRight" class="nav-right">
<!--            <li style="cursor:pointer;" [ngStyle]="{'opacity': lang == 'pt' ? '1' : '0.5'}">-->
<!--              <a (click)="useLanguage('pt')">-->
<!--                <img class="header-flag" src="assets/images/flags/Brazil.png" alt="PT-BR">-->
<!--                <span class="header-language" [ngClass]="{'header-language-selected': lang == 'pt'}">{{'general.portuguese' | translate}}</span>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li class="header-dot">.</li>-->
<!--            <li style="cursor:pointer;" [ngStyle]="{'opacity': lang == 'en' ? '1' : '0.5'}">-->
<!--              <a (click)="useLanguage('en')">-->
<!--                <img class="header-flag" src="assets/images/flags/United-States.png" alt="EN-US">-->
<!--                <span class="header-language" [ngClass]="{'header-language-selected': lang == 'en'}">{{'general.english' | translate}}</span>-->
<!--              </a>-->
<!--            </li>-->
            <li (clickOutside)="notificationOutsideClick('profile')" class="user-profile header-notification" [ngClass]="profileNotificationClass" (click)="toggleProfileNotification()">
              <a href="javascript:">
                <!--img src="assets/images/avatar-4.jpg" class="img-radius" alt="User-Profile-Image"-->
<!--                <span class="header-username">{{ user.fullname.split(" ")[0] }}</span>-->
                <span class="header-username">{{ user.fullname}}</span>
                <i class="icon-angle-down"></i>
              </a>
              <ul [@notificationBottom]="profileNotification" class="show-notification profile-notification">
                <li (click)="modalChangeData.show()">
                  <a>
                    <i class="icon-settings"></i> {{ 'home.changeData' | translate }}
                  </a>
                </li>
                <li (click)="modalChangePassword.show()">
                  <a>
                    <i class="icon-lock"></i> {{ 'home.changePassword' | translate }}
                  </a>
                </li>
                <li (click)="doLogout()">
                  <a>
                    <i class="icon-layout-sidebar-left"></i> {{ 'general.logout' | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="pcoded-main-container" [style.margin-top]="headerFixedMargin">
      <div class="pcoded-wrapper">
        <nav
          id="main_navbar"
          class="pcoded-navbar"
          [attr.pcoded-header-position]="pcodedHeaderPosition"
          [attr.navbar-theme]="navBarTheme"
          [attr.active-item-theme]="activeItemTheme"
          sub-item-theme="theme2"
          active-item-style="style0"
          [attr.pcoded-navbar-position]="pcodedSidebarPosition"
        >
          <div class="pcoded-inner-navbar main-menu" appAccordion>
            <perfect-scrollbar [style.max-width]="'235px'" [style.max-height]="sidebarFixedHeight" [config]="config" style="margin-top: 10px;">
              <span *ngFor="let asideItems of menuItems.getAll()" class="d-color">
                <ul class="pcoded-item pcoded-left-item" [attr.item-border]="itemBorder" [attr.item-border-style]="itemBorderStyle" [attr.subitem-border]="subItemBorder" *ngFor="let asideItem of asideItems.main" appAccordionLink group="{{asideItem.state}}">
                  <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" *ngIf="asideItem.type === 'external'" appAccordionLink group="{{asideItem.state}}">
                    <a href="{{asideItem.external}}" target="{{asideItem.target ? '_blank' : '_self'}}" appAccordionToggle>
                      <span class="pcoded-micon"><i class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label }}</b></span>
                      <span class="pcoded-mtext">{{ asideItem.name | translate }}</span>
                      <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                      <span class="pcoded-mcaret"></span>
                    </a>
                  </li>

                  <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" *ngIf="asideItem.type === 'link'" appAccordionLink group="{{asideItem.state}}">
                    <a [routerLink]="['/', asideItem.main_state, asideItem.state]" target="{{asideItem.target ? '_blank' : '_self'}}" appAccordionToggle *ngIf="asideItem.main_state; else mainContent">
                      <span class="pcoded-micon"><i class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label }}</b></span>
                      <span class="pcoded-mtext">{{ asideItem.name | translate }}</span>
                      <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                      <span class="pcoded-mcaret"></span>
                    </a>
                    <ng-template #mainContent>
                      <a [routerLink]="['/', asideItem.state]" target="{{asideItem.target ? '_blank' : '_self'}}" appAccordionToggle>
                        <span class="pcoded-micon"><i class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label }}</b></span>
                        <span class="pcoded-mtext">{{ asideItem.name | translate }}</span>
                        <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                        <span class="pcoded-mcaret"></span>
                      </a>
                    </ng-template>
                  </li>
                  <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" *ngIf="asideItem.type === 'sub'" class="pcoded-hasmenu" [attr.dropdown-icon]="dropDownIcon" [attr.subitem-icon]="subItemIcon" appAccordionLink group="{{asideItem.state}}">
                    <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" href="javascript:;" appAccordionToggle>
                      <span class="pcoded-micon"><i class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label }}</b></span>
                      <span class="pcoded-mtext">{{ asideItem.name | translate }}</span>
                      <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                      <span class="pcoded-mcaret"></span>
                    </a>
                    <ul class="pcoded-submenu">
                      <ng-template ngFor let-asideChildren [ngForOf]="asideItem.children">
                        <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" *ngIf="asideChildren.type !== 'sub'">
                          <a [routerLink]="['/', asideItem.state, asideChildren.state]" target="{{asideChildren.target ? '_blank' : '_self'}}">
                            <span class="pcoded-micon"><i class="icon-angle-right"></i></span>
                            <span class="pcoded-mtext">{{ asideChildren.name | translate }} </span>
                            <span *ngFor="let asideChildrenBadge of asideChildren.badge" class="pcoded-badge label label-{{ asideChildrenBadge.type }}">{{asideChildrenBadge.value}}</span>
                            <span class="pcoded-mcaret"></span>
                          </a>
                        </li>

                        <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" class="pcoded-hasmenu" [attr.dropdown-icon]="dropDownIcon" [attr.subitem-icon]="subItemIcon" *ngIf="asideChildren.type === 'sub'" appAccordionLink group="sub-toggled">
                          <a href="javascript:;" appAccordionToggle>
                            <span class="pcoded-micon"><i class="icon-direction-alt"></i></span>
                            <span class="pcoded-mtext">{{ asideChildren.name | translate }}</span>
                            <span *ngFor="let asideChildrenBadge of asideChildren.badge" class="pcoded-badge label label-{{ asideChildrenBadge.type }}">{{asideChildrenBadge.value}}</span>
                            <span class="pcoded-mcaret"></span>
                          </a>
                          <ul class="pcoded-submenu">
                            <ng-template ngFor let-asideChildrenSub [ngForOf]="asideChildren.children">
                              <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
                                <a [routerLink]="['/', asideItem.state, asideChildren.state, asideChildrenSub.state]" target="{{asideChildrenSub.target ? '_blank' : '_self'}}">
                                  <span class="pcoded-micon"><i class="icon-angle-right"></i></span>
                                  <span class="pcoded-mtext">{{ asideChildrenSub.name | translate }}</span>
                                  <span *ngFor="let asideChildrenSubBadge of asideChildrenSub.badge" class="pcoded-badge label label-{{ asideChildrenSubBadge.type }}">{{asideChildrenSubBadge.value}}</span>
                                  <span class="pcoded-mcaret"></span>
                                </a>
                              </li>
                            </ng-template>
                          </ul>
                        </li>
                      </ng-template>
                    </ul>
                  </li>
                </ul>

              </span>
<!--              <ul class="pcoded-item pcoded-left-item">-->
<!--                <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">-->
<!--                  <a [routerLink]="['/trays']" >-->
<!--                    <span class="pcoded-micon"><i class="icon-cb-trays"></i><b></b></span>-->
<!--                    <span class="pcoded-mtext">{{ 'home.trays' | translate }}</span>-->
<!--                    <span class="pcoded-mcaret"></span>-->
<!--                  </a>-->
<!--                </li>-->
<!--              </ul>-->
              <!-- <ul class="pcoded-item pcoded-left-item">
                <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
                  <a [routerLink]="['/trash']" >
                    <span class="pcoded-micon"><i class="icon-cb-trash"></i><b></b></span>
                    <span class="pcoded-mtext">{{ 'home.trash' | translate }}</span>
                    <span class="pcoded-mcaret"></span>
                  </a>
                </li>
              </ul> -->
<!--              <div class="pcoded-navigation-label side-bar-info" *ngIf="isTraysRoute()" menu-title-theme="theme1">-->
<!--                  <p>-->
<!--                    <span class="text-uppercase">{{sizeUtil.parseToString(user.usedSpace)}}</span> {{'general.using' | translate}}-->
<!--                  </p>-->
<!--              </div>-->
              <div class="sidebar-version">
                <div class="container">
                  <div class="row">
                    <div class="col-6 corp-name">
                      <p>{{corp}}</p>
                    </div>
                    <div class="col-6">
                      <app-version></app-version>
                    </div>
                  </div>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </nav>
        <div class="pcoded-content">
          <div class="pcoded-inner-content">
            <!--  Main-body start -->
            <div class="main-body">
              <div class="page-wrapper">
                   <!-- <app-main [languageMain]="lang"></app-main> -->
                   <router-outlet></router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- change data modal -->
  <app-change-data #modalChangeData (changedUserInfoEvent)="onChangedUserInfo($event)"></app-change-data>
  <!-- change password modal -->
  <app-change-password #modalChangePassword></app-change-password>
</div>

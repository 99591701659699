import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() paths: string[] = [];
  @Input() home: string = "";
  @Output() clickPath = new EventEmitter<number>();

  constructor() {}

  ngOnInit() {
  }

  onClickPath(pathPosition: number) {
    this.clickPath.emit(pathPosition);
  }

}

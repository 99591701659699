import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ToastyModule } from 'ng2-toasty';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { SizeUtil } from './utils/size.util';
import { AuthService } from './services/auth.service';
import { ApiService } from './services/api.service';
import { UserService } from './services/user.service';
import { PreferenceService } from './services/preference.service';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { PrivatePageGuard } from './services/private-page.guard';
import { PublicPageGuard } from './services/public-page.guard';
import { SpinnerComponent } from './spinner/spinner.component';
import { SpinnerService } from './spinner/spinner.service';
import { VersionComponent } from './version/version.component';
import { AccordionAnchorDirective } from './accordion/accordionanchor.directive';
import { AccordionLinkDirective } from './accordion/accordionlink.directive';
import { AccordionDirective } from './accordion/accordion.directive';
import { CardToggleDirective } from './card/card-toggle.directive';
import { CardComponent } from './card/card.component';
import { NotificationService } from './services/notification.service';
import { ModalBasicComponent } from './modal-basic/modal-basic.component';
import { ModalAnimationComponent } from './modal-animation/modal-animation.component';
import { ApiAuthS3Service } from "./services/api-auth-s3.service";
import {StringsService} from "./services/strings.service";
import {ApiZimbraService} from "./services/api-zimbra.service";
import {ApiEbcorpService} from "./services/api-ebcorp.service";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    HttpClientModule,
    PerfectScrollbarModule,
    TranslateModule,
    ToastyModule.forRoot()
  ],
  exports: [
    NgbModule,
    HttpClientModule,
    PerfectScrollbarModule,
    SpinnerComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    CardToggleDirective,
    CardComponent,
    VersionComponent,
    ToastyModule,
    ModalBasicComponent,
    ModalAnimationComponent,
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    SpinnerComponent,
    CardToggleDirective,
    CardComponent,
    VersionComponent,
    ModalBasicComponent,
    ModalAnimationComponent,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    AuthService,
    ApiService,
    ApiZimbraService,
    ApiAuthS3Service,
    ApiEbcorpService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    UserService,
    PreferenceService,
    SizeUtil,
    SpinnerService,
    PrivatePageGuard,
    PublicPageGuard,
    NotificationService,
    StringsService
  ]
})
export class SharedModule {}

import { Injectable } from '@angular/core';
import {ToastData, ToastOptions, ToastyService} from 'ng2-toasty';

@Injectable()
export class NotificationService {

  constructor() { }
  position = 'bottom-right';
  title: string;
  showClose = true;
  theme = 'default';
  type = 'error';
  closeOther = true;

  notifyError(t: ToastyService, title: string, msg: string, timeout = 3000) {
    if (this.closeOther) {
      t.clearAll();
    }
    const toastOptions: ToastOptions = {
      title: title,
      msg: msg,
      showClose: this.closeOther,
      timeout: timeout,
      theme: this.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };
    t.error(toastOptions);
    // switch (this.type) {
    //   case 'default': t.default(toastOptions); break;
    //   case 'info': t.info(toastOptions); break;
    //   case 'success': t.success(toastOptions); break;
    //   case 'wait': t.wait(toastOptions); break;
    //   case 'error': t.error(toastOptions); break;
    //   case 'warning': t.warning(toastOptions); break;
    // }
  }

  notifySuccess(t: ToastyService, title: string, msg: string) {
    if (this.closeOther) {
      t.clearAll();
    }
    const toastOptions: ToastOptions = {
      title: title,
      msg: msg,
      showClose: this.closeOther,
      timeout: 3000,
      theme: this.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };
    t.success(toastOptions);
    // switch (this.type) {
    //   case 'default': t.default(toastOptions); break;
    //   case 'info': t.info(toastOptions); break;
    //   case 'success': t.success(toastOptions); break;
    //   case 'wait': t.wait(toastOptions); break;
    //   case 'error': t.error(toastOptions); break;
    //   case 'warning': t.warning(toastOptions); break;
    // }
  }

  notifyInfo(t: ToastyService, title: string, msg: string) {
    if (this.closeOther) {
      t.clearAll();
    }
    const toastOptions: ToastOptions = {
      title: title,
      msg: msg,
      showClose: this.closeOther,
      timeout: 3000,
      theme: this.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };
    t.info(toastOptions);
    // switch (this.type) {
    //   case 'default': t.default(toastOptions); break;
    //   case 'info': t.info(toastOptions); break;
    //   case 'success': t.success(toastOptions); break;
    //   case 'wait': t.wait(toastOptions); break;
    //   case 'error': t.error(toastOptions); break;
    //   case 'warning': t.warning(toastOptions); break;
    // }
  }


}

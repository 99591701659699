import {Injectable} from "@angular/core";
import {ApiZimbraService} from "../../../shared/services/api-zimbra.service";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {UserModel} from "./new-user/user.model";
import {ApiAdminService} from "../../../shared/services/api-admin.service";
import {map} from "rxjs/operators";

@Injectable()
export class AccountService {

  static PATH_BACKUP = "/api/backup";


  constructor(
    private apiZimbraService: ApiZimbraService,
    private apiAdmin: ApiAdminService) {
  }

  getEmails(nomeGuerra: string, nomeCompleto: string): Observable<any>{

    let header: Map<string, string> = new Map()
      .set('nomeGuerra', nomeGuerra)
      .set('fullName', nomeCompleto)
      .set('domain', environment.domain);

    return this.apiZimbraService.get('/api/admin/valid-mails', header);
  }

  createUser(user: UserModel, email: string, password: string) {
    const body = {
      email: email,
      password: password,
      cpf: user.cpf.replace(/\D+/g, ''),
      displayName: user.postoMilitar + " - " + user.nomeCompleto ,
      om: 'usto', //temp value
      codigoOm: 'usto.re', //temp value
      nomeCompleto: user.nomeCompleto,
      patente: user.postoMilitar
    };

    return this.apiZimbraService.post('/api/zm',body);  }

  createUserOffice(user: UserModel, email: string, password: string) {
    const body = {
      email: email,
      password: password,
      cpf: user.cpf,
//      displayName: userDgp.postoMilitar + " " + userDgp.nomeGuerra + " - " + userDgp.siglaOm,
      displayName: user.postoMilitar + " " + user.nomeGuerra + " - " + user.om,
      om: user.om, //userDgp.siglaOm,
      codigoOm: user.codigoOm,
      nomeCompleto: user.nomeCompleto, //userDgp.nomeMilitar,
      patente: user.postoMilitar
    };

    return this.apiZimbraService.post('/api/zm/office',body);  }


  getAllAccounts(pageSize: number, pageNumber: number, search: string): Observable<any> {
    const header = new Map();
    header.set('pageSize', pageSize.toString());
    header.set('page', pageNumber.toString());
    header.set('filtered', 'true');
    if (search) {
      header.set('regex', search);
    }
    return this.apiAdmin.get('/api/users/filtered', header).pipe(map(
      data => {
        return data;
      }));
  }

  getOfficeAccounts(page, pageSize, search?){
    const header = new Map()
    const pag = page -1
    header.set('page', pag.toString());
    header.set('pageSize', pageSize.toString())
    header.set('search', search? search : "")
    console.log("headers", header);
    return this.apiZimbraService.get("/api/zm/officeusers", header)
  }

  getCompanyById(groupId: any): Observable<any> {
    const header = new Map();
    header.set('id', groupId.toString());
    return this.apiAdmin.get('/companies', header).pipe(map(
      (data: any) => {
        data.telephone1 = data.phones[0];
        if (data.phones.length >= 2) {
          data.telephone2 = data.phones[1];
        }
        data.email = data.emails[0];
        data.address = data.addresses[0];
        data.person = data.person === 'CPF' ? 0 : 1;
        delete data.phones;
        delete data.emails;
        delete data.addresses;
        return data;
      }));
  }

  getAccountById(id: number): Observable<any> {
    return this.apiAdmin.get('/api/users/' + id);
  }

  activateUser(id: number, email: string): Observable<any> {
    const header = new Map();
    header.set('email', email);
    return this.apiZimbraService.post('/api/zm/activate/'+id, undefined, header);
  }

  deactivateUser(id: number, email: string): Observable<any> {
    const header = new Map();
    header.set('email', email);
    return this.apiZimbraService.post('/api/zm/deactivate/'+id, undefined, header);
  }

  getCountAccounts(search?: string) {
    const header = new Map();
    header.set('filtered', 'true');
    if (search) {
      header.set('regex', search);
    }
    return this.apiAdmin.get('/api/users/filtered/count', header).pipe(map(
      data => {
        return data;
      }));
  }

  purgeUser(email:string) {
    const header = new Map();
    header.set("email",email )
    return this.apiZimbraService.delete("/api/zm/purge/", header)

  }

  deleteUserOffice(email: string) {
    const header = new Map()
    header.set('email', email)
    return this.apiZimbraService.delete("/api/zm/officeuser/", header)

  }
}

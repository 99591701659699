import {Injectable} from '@angular/core';

@Injectable()
export class SizeUtil {

  constructor() { }

  parseToString(bytes: number): string {
    if (bytes <= 0) {
      return '0 B';
    }
    try {
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      const size = (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
      return size.toString();
    } catch (e) {
      return '0 B';
    }
  }

}

<app-modal-basic #modalconfirmComponent>
  <div class="app-modal-header card-header-text">
    <div *ngIf="block; else elseBlock"> Tem certeza que deseja ativar conta?</div>
    <ng-template #elseBlock>Tem certeza que deseja desativar conta?</ng-template>
  </div>
  <div class="app-modal-body">
<!--    <div *ngIf="block; else elseBlock"> Confirmar ativação de conta?</div>-->
<!--    <ng-template #elseBlock>Confirmar desativação de conta de conta?</ng-template>-->
    Nome: {{this.fullname}} <br>
    Email: {{this.email}}

  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-danger waves-effect " (click)="modalconfirmComponent.hide()">{{'general.cancel' | translate}}</button>
    <button type="button" class="btn btn-primary waves-effect waves-light"
            (click)="onConfirm()">
            {{'general.confirm' | translate}}
    </button>
  </div>
</app-modal-basic>
<ng2-toasty></ng2-toasty>

import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../../../shared/services/api.service';

@Injectable()
export class RecoverService {

  readonly ERROR_INVALID_DATA = '-1';

  constructor(private apiService: ApiService) { }

  changePassword(login: string, resetCode: string, password: string): Observable<any> {
    const body = {
      login: login,
      resetCode: resetCode,
      newPassword: password
    };

    return this.apiService.post('/sso/changeExpiredPassword', body);
  }

}

import { Injectable } from "@angular/core";
import { config } from '../config/string';
import { environment } from "../../../environments/environment";

@Injectable()
export class StringsService {

    public from(value): string {
      let result = config[environment.name];
        if(result)
            return result[value];
        else
            return "";
    }

}

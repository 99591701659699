import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {TranslateService} from "@ngx-translate/core";
import {MatTableDataSource} from "@angular/material/table";
import {VpnMetadata} from "../../../shared/domains/metadata/vpn-metadata";
import {RestoreService} from "./restore.service";
import {SpinnerService} from "../../../shared/spinner/spinner.service";

@Component({
  selector: 'app-vpn',
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.scss']
})
export class RestoreComponent implements AfterViewInit {

  @ViewChild(MatPaginator) matPaginator: MatPaginator;

  dataSource = new MatTableDataSource<VpnMetadata>();
  displayedColumns = ['backup', 'type' , 'actionTable'];
  mobile = false;
  tablet = false;
  totalFiles = 0;

  paths: string[] = [];
  breadcrumb: string = "";
  showUpload = true;

  constructor(private translate: TranslateService,
              private zimbraService: RestoreService,
              private spinnerService: SpinnerService) { }

  ngAfterViewInit(): void {
    this.translate.get('restore').subscribe(i=>{
      this.breadcrumb = i.title
    });
    this.getBackups();
  }

  getBackups(){
    this.spinnerService.showSpinner(true);
    this.zimbraService.getListBackups().subscribe(data =>{
      console.log(data);
      this.dataSource.data = data.list;
      this.matPaginator.length = data.length;
      this.spinnerService.hideSpinner();
    },error => {
      console.log('error: ', error);
      this.spinnerService.hideSpinner();
    });
  }

  formatDate(data){
    let date = new Date(data.startTime);
    return date;
  }

  onClickPath(pathPosition: number) {
    if(pathPosition === 0){
      this.paths = [];
      this.matPaginator.pageIndex = 0;
      this.getBackups();
    }
  }

  onScroll(event) {
    const showUpload = event.target.scrollTop < 20;
    if (this.showUpload === showUpload) {
      return;
    }
    const mainComponentHeight = event.target.parentElement.parentElement.parentElement.offsetHeight;
    const listComponentHeight = event.target.scrollHeight;
    if (showUpload || (!showUpload && listComponentHeight >= mainComponentHeight - 20)) {
      this.showUpload = showUpload;
    }
  }

  onResize(event) {
    // this.hideColumns(event.target.innerWidth);
  }

  hideColumns(width) {
    this.mobile = false;
    this.tablet = false;
    this.displayedColumns = ['name', 'modified', 'owner', 'writePermission',  'actionTable'];
    if (width >= 768 && width <= 1024) {
      this.tablet = true;
    } else if (width < 768) {
      this.mobile = true;
    }
  }

  onChangePageListener (value) {
    console.log('liste: ', value)
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { SharedModule } from '../../shared/shared.module';
import { ForgotComponent } from './forgot/forgot.component';
import { ForgotService } from './forgot/forgot.service';
import { RecoverComponent } from './recover/recover.component';
import { RecoverService } from './recover/recover.service';
import { ResendEmailComponent } from './forgot/resend-email/resend-email.component';
import { FirstAccessComponent } from './first-access/first-access.component';
import {FirstAccessService} from "./first-access/first-access.service";

@NgModule({
  imports: [
    CommonModule,
    LoginRoutingModule,
    TranslateModule,
    FormsModule,
    SharedModule
  ],
  declarations: [
    LoginComponent,
    ForgotComponent,
    RecoverComponent,
    ResendEmailComponent,
    FirstAccessComponent
  ],
  providers: [
    ForgotService,
    RecoverService,
    FirstAccessService
  ]
})
export class LoginModule { }

<app-modal-basic #modalSharePublic [hideFooter]="true">
  <div class="app-modal-header">
    <h4 class="modal-title">{{ 'restore.title' | translate }}</h4>
    <button type="button" class="close basic-close" (click)="hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">

    <div class="card">
      <div class="card-body">
        <label class="small">{{'restore.info_message' | translate}}</label>
        <div class="row">
          <input type="text" title="E-mail" [(ngModel)]="email" placeholder="{{'restore.email_message' | translate}}">
          <input type="button" title="Share Public" class="btn btn-primary waves-effect waves-light" (click)="restore()" style="margin-left: 10px" value="{{'restore.title' | translate}}">
        </div>
      </div>
      <div >
      </div>
    </div>


  </div>
</app-modal-basic>
<ng2-toasty></ng2-toasty>

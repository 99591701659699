<div class="row">
  <div class="col-sm-8">
    <app-breadcrumb [paths]="paths" [home]="breadcrumb" (clickPath)="onClickPath($event)"></app-breadcrumb>
  </div>
</div>
<div id="input-search" class="input-group" title="{{ 'vpn.placeholder' | translate }}" >
  <input id="searchBar" (keyup.enter)="searchOn()" [(ngModel)]="searchWord" placeholder="{{ 'vpn.placeholder' | translate }}"/>
  <span   class="search-btn" (click)="searchOn()"><i class="icon-search" ></i></span>
</div>
<div class="div-line"></div>
<div class="table-container">
  <mat-table #table [dataSource]="dataSource" (scroll)="onScroll($event)" (window:resize)="onResize($event)">
    <!-- Position Column -->
    <!--    <ng-container matColumnDef="id">-->
    <!--      <mat-header-cell *matHeaderCellDef class="select-table">-->
    <!--        <mat-checkbox (change)="$event ? masterToggle() : null; selectChange()"-->
    <!--                      [checked]="selection.hasValue() && isAllSelected()"-->
    <!--                      [indeterminate]="selection.hasValue() && !isAllSelected()">-->
    <!--        </mat-checkbox>-->
    <!--      </mat-header-cell>-->
    <!--      <mat-cell *matCellDef="let row" class="select-table">-->
    <!--        <mat-checkbox-->
    <!--          (change)="$event ? selection.toggle(row) : null; selectChange()"-->
    <!--          [checked]="selection.isSelected(row)">-->
    <!--        </mat-checkbox>-->
    <!--
    <img type="image" class="shared-item"
    *ngIf="row.publicFile === 1"
    src="../../../../../assets/images/home/link.png" />
    <img type="image" class="shared-item"
    *ngIf="row.hasUserSharing"
    src="../../../../../assets/images/home/share_person.png" />
    <img type="image" class="shared-item"
    *ngIf="row.hasGroupSharing"
    src="../../../../../assets/images/home/share_group.png" />
    -->
    <!--      </mat-cell>-->
    <!--    </ng-container>-->

    <!-- Name Column -->
    <ng-container matColumnDef="email">
      <mat-header-cell class="title-table-name" *matHeaderCellDef> {{'vpn.name_table' | translate}}</mat-header-cell>

      <mat-cell *matCellDef="let element" class="name-file-title">
        <div class="name-container">
          <div class="name-container-text">{{element.email }}</div>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="actionTable">
      <mat-header-cell class="title-table action-table"  *matHeaderCellDef> {{'home.action_table' | translate}} </mat-header-cell>
      <mat-cell class="action-icon-container action-table" *matCellDef="let element">
        <input type="image" title="{{'vpn.revoke' | translate}}" class="vpn-item vpn-icon" src="../../../../assets/images/upload/download_falha.png" (click)="concessaoVpn(element.email, '0')"/>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row class="list-item"  *matRowDef="let row; columns: displayedColumns;"></mat-row>

  </mat-table>

  <mat-paginator (page)="onChangePageListener($event)" #paginator [pageSize]="10"
                 [pageSizeOptions]="[5, 10, 20, 50]" [length]="totalFiles" >
  </mat-paginator>

  <div class="row text-center mt-4" *ngIf="totalFiles == 0">
    <div class="col-sm-12">
      <a class="no-item">
        {{ 'home.no_files_folder' | translate }}
      </a>
    </div>
  </div>
</div>

<ng2-toasty></ng2-toasty>

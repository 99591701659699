import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import { ToastyService } from 'ng2-toasty';


import { AuthService } from '../../shared/services/auth.service';
import { User } from '../../shared/domains/user';
import { PreferenceService } from '../../shared/services/preference.service';
import { UserService } from '../../shared/services/user.service';
import { NotificationService } from '../../shared/services/notification.service';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { ApiAuthS3Service } from "../../shared/services/api-auth-s3.service";
import { FirstAccessComponent } from './first-access/first-access.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss',
    '../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../node_modules/ng2-toasty/style-material.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  @ViewChild(FirstAccessComponent) modalFirstAccess: FirstAccessComponent;

  lang: string;
  usernameValue: string;
  rememberValue = false;

  /**
   *  internationalization variable
   */
  enterEmailFailed: string;
  enterPasswordFailed: string;
  enterCredentialIsEmpty: string;
  titleError: string;
  authenticationFailed: string;
  tryAgainFewMoments: string;
  loginOrPassIsInvalid: string;
  errorInvalidLogin: string;
  errorInactiveUser: string;
  errorAccessDenied: string;
  errorInvalidLicense: string;
  login: string;
  password: string;
  isEBmail: boolean;

  constructor(private translate: TranslateService,
              private service: AuthService,
              private preference: PreferenceService,
              private userService: UserService,
              private router: Router,
              private notification: NotificationService,
              private toastyService: ToastyService,
              public spinnerService: SpinnerService,
              public authS3Service: ApiAuthS3Service,
              private route: ActivatedRoute) {}

  /**
   * get language and lastLogin set in localStorage
   */
  initPreference(): void {
    this.useLanguage("pt")
    if (this.preference.getPreference()) {
      const pref = this.preference.getPreference();
      this.useLanguage('pt');
      if (pref.lastLogin) {
        this.usernameValue = pref.lastLogin;
        this.rememberValue = true;
      }
    }
    this.useLanguage("pt")
  }
  /**
   * If session salved, go home. Else stay in login
   */
  checkSessionSaved() {
    if (this.service.isSignedIn()) {
      this.router.navigate(['home']);
    }
  }

  ngOnInit() {
    this.checkSessionSaved();
    this.initPreference();
    this.route.queryParams
      .subscribe(params => {
        this.login = params['code'] ? params['code'] : "";
        this.password = params['hash'] ? params['hash'] : "";
        this.isEBmail = params['isEBmail'] ? params['isEBmail'] : false;
      });
    if(this.login != "" && this.password != "" && this.isEBmail != false){
      this.doLogin(this.login,this.password, false);
    }

  }

  /**
   * get internationalization from i18n
   */
  getInternationalizationVariable(): void {
    this.translate.get('login')
      .subscribe(i => {
        this.enterEmailFailed = i.error_enter_username;
        this.enterPasswordFailed = i.error_enter_password;
        this.enterCredentialIsEmpty = i.error_credential_empty;
        this.titleError = i.error_missing_data;
        this.authenticationFailed = i.error_authentication_failed;
        this.tryAgainFewMoments = i.error_try_again_few_moments;
        this.loginOrPassIsInvalid = i.login_or_pass_is_invalid;
        this.errorInvalidLogin = i.error_invalid_login;
        this.errorInactiveUser = i.error_inactive_user;
        this.errorInvalidLicense = i.error_invalid_license;
      });
  }
  /**
   * Change and save language
   * @param language
   */
  clickLanguage(language: string) {
    this.useLanguage(language);
    this.saveLanguage(language);
  }
  /**
   * Change language
   * @param language
   */
  useLanguage(language: string) {
    this.translate.use(language);
    this.lang = language;
  }

  /**
   * Save language
   * @param language
   */
  saveLanguage(language: string) {
    this.preference.saveLanguage(language);
  }

  /**
   * Save last login
   * @param login
   */
  saveLogin(login: string) {
    this.preference.saveLastLogin(login);
  }

  /**
   * get language
   * @return language
   */
  getLanguage() {
    return this.lang;
  }

  /**
   * When the inputs are empty
   * @param username input value username
   * @param password input value password
   */
  notifyErrorWhenCredentialEmpty(username: String, password: String) {
    this.getInternationalizationVariable();
    if (this.isEmpty(username) && this.isEmpty(password)) {
      this.notification.notifyError(this.toastyService, this.titleError, this.enterCredentialIsEmpty);
    }else if (this.isEmpty(username)) {
      this.notification.notifyError(this.toastyService, this.titleError, this.enterEmailFailed);
    }else if (this.isEmpty(password)) {
      this.notification.notifyError(this.toastyService, this.titleError, this.enterPasswordFailed);
    }
  }
  isEmpty(str) {
    return (!str || 0 === str.length);
  }

  doLogin(username, password, remember) {
      this.spinnerService.showSpinner(true);
      this.getInternationalizationVariable();
      this.service.login(username, password).subscribe(
        (data: any) => {
          this.saveLogin(null);
          if (remember) {
            this.saveLogin(username);
          }
          const user: any = {
            'code': data.token,
            'email': data.user.email,
            'fullname': data.user.email,
            'login': data.user.login,
            'id': data.user.id
          };
          this.userService.saveUser(user);
          this.spinnerService.hideSpinner();
          this.router.navigate(['home']);
        },
        err => {
          this.spinnerService.hideSpinner();
          this.getInternationalizationVariable();
          if (err.status === 401) {
            this.notification.notifyError(this.toastyService, this.authenticationFailed, this.errorInvalidLogin);
          }
          /*if (err.error.message === this.service.ERROR_INACTIVE_USER) {
            this.notification.notifyError(this.toastyService, this.authenticationFailed, this.errorInactiveUser);
          } else if (err.error.message === this.service.ERROR_INVALID_USER) {
            this.notification.notifyError(this.toastyService, this.authenticationFailed, this.errorInvalidLogin);
          }*/ else {
            this.notification.notifyError(this.toastyService, this.authenticationFailed, this.tryAgainFewMoments);
          }

        }
      );
  }

  getUserInfo() {
    this.service.getUserInfo().subscribe(
      data => {
         this.getUserCompany(data.toString());
      },
      err => {
        this.userService.destroyAll();
        this.spinnerService.hideSpinner();
        this.notification.notifyError(this.toastyService, this.authenticationFailed, this.tryAgainFewMoments);
      }
    );
  }

  getUserCompany(companyId: string) {
    // this.spinnerService.hideSpinner();
    // this.router.navigate(['home']);
    this.service.getLicenseByCompanyId(companyId).subscribe(
      // this.service.getUserCompany(companyId).subscribe(
      data => {
        this.authS3Service.getUserS3Info(this.userService.getLicense(), "true").subscribe(
          data => {
            this.userService.saveS3Info(data);
            this.router.navigate(['home']);
          }, err => {
            this.spinnerService.hideSpinner();
            this.service.logout();
            if(err.error === 'License invalid') {
              this.notification.notifyError(this.toastyService, this.authenticationFailed, this.errorInvalidLicense);
            } else {
              this.notification.notifyError(this.toastyService, this.authenticationFailed, this.tryAgainFewMoments);
            }
          });
      },
      err => {
        this.service.logout();
        this.spinnerService.hideSpinner();
        this.notification.notifyError(this.toastyService, this.authenticationFailed, this.tryAgainFewMoments);
      }
    );
  }
}






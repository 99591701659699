

export class UserModel {
    email: string = "";
    cpf: string;
    displayName: string = "";
    om: string = "";
    codigoOm: string = "";
    password: string = "";
    nomeCompleto: string = "";
    patente: string = "";
    postoMilitar: string = "";
    nomeGuerra: string = "";

}

import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../../../shared/services/api.service';

@Injectable()
export class ForgotService {
  // esperando api
  readonly ERROR_INVALID_DATA = 'xxxxx';

  constructor(private apiService: ApiService) { }

  recoverPassword(login: string, email: string): Observable<any> {
    const body = {
      login: login,
      email: email
    };

    return this.apiService.post('/api/recoveryPassword', body);

  }

}

<div class="card breadcrumb-bg">
    <div class="card-block">
      <div>
        <ul class="breadcrumb-title">
          <li class="breadcrumb-item">
            <a class="breadcrumb-st" (click)="onClickPath(0)"
             [ngClass]="{'breadcrumb-selected-path': paths.length == 0}">
              {{ this.home }}
            </a>
          </li>
          <li class="breadcrumb-item" *ngFor="let path of paths; let i = index">
            <a class="breadcrumb-st" (click)="onClickPath(i+1)"
              [ngClass]="{'breadcrumb-selected-path': paths.length == i+1}">{{path}}</a>
          </li>
        </ul>
      </div>
    </div>
</div>

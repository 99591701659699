import { Injectable } from '@angular/core';
import { Preference } from '../domains/preference';


@Injectable()
export class PreferenceService {

  saveLanguage(lang: string) {
    let pref: Preference = {};
    if (this.getPreference()) {
      pref = this.getPreference();
    }
    pref.lang = lang;
    this.savePreference(pref);
  }

  saveLastLogin(login: string) {
    let pref: Preference = {};
    if (this.getPreference()) {
      pref = this.getPreference();
    }
    pref.lastLogin = login;
    this.savePreference(pref);
  }

  getPreference(): Preference {
    if (window.localStorage['pref']) {
      return JSON.parse(window.localStorage['pref']);
    }
    return null;
  }

  savePreference(pref: Preference) {
    window.localStorage['pref'] = JSON.stringify(pref);
  }

  destroyPreference() {
    window.localStorage.removeItem('pref');
  }

}

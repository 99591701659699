<app-modal-basic #modalconfirmPurgeUser>
  <div class="app-modal-header card-header-text">
    <div > Tem certeza que deseja excluir este usuáro permanentemente?</div>
  </div>
  <div *ngIf="isOfficeAccount">
      <div class="app-modal-body text-justify">
        <span class="card-header-text text-justify">Usuário: {{this.fullname}}</span>
<!--        Conta Oficial.-->
        <br>
        ATENÇÃO: ao excluir o usuário, o mesmo perderá acesso à conta e emails.
        <br>
        Esta ação não pode ser desfeita.

      </div>
      <div class="app-modal-footer">
        <button type="button" class="btn btn-outline-secondary " (click)="modalconfirmPurgeUser.hide()">{{'general.cancel' | translate}}</button>
        <button type="button" class="btn btn-outline-danger waves-effect " (click)="onConfirmOfficeUser()"> {{'general.confirm' | translate}} </button>
      </div>
  </div>
  ----------------------------------------
  <div class="app-modal-body text-justify">
    <br>
<!--    Conta não Oficial.-->
    ATENÇÃO: Ao excluir o usuário, o mesmo perderá acesso aos emails e arquivos.
    <br>
    Esta ação não pode ser desfeita.

  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-outline-secondary " (click)="modalconfirmPurgeUser.hide()">{{'general.cancel' | translate}}</button>
    <button type="button" class="btn btn-outline-danger waves-effect " (click)="onConfirm()"> {{'general.confirm' | translate}} </button>
  </div>
</app-modal-basic>
<ng2-toasty></ng2-toasty>

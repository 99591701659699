import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import {MatDrawer} from "@angular/material/sidenav";
import {FileView} from "../../../shared/domains/fileView";
import {SpinnerService} from "../../../shared/spinner/spinner.service";
import {ChartDataSets, ChartOptions, ChartType, ChartColor, Chart} from 'chart.js';
import {Color, defaultColors, Label} from 'ng2-charts';
import {HomeService} from "../home.service";
import {Dashboard} from "../../../shared/domains/dashboard";
import {ApiAdminService} from '../../../shared/services/api-admin.service';
import {ToastyService} from 'ng2-toasty';


@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']

})
export class MainComponent implements OnInit {
    showUpload = true;
    actualPath = "";
    fileSelected: FileView = new FileView();
    @Input() languageMain;
    @ViewChild('drawer') detailsSide: MatDrawer;

    public barChartOptions: ChartOptions = {
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      },
    };
    public barChartLabels: Label[] = [];
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartPlugins = [];
    public barChartData: ChartDataSets[] = [];

    public barChartDataAccount: ChartDataSets[] = [{data: [], label: ''}];
    public barChartLabelsAccount: Label[] = ["Contas Criadas"];
    public barChartDataEmails: ChartDataSets[] = [{data: [], label: ''}];
    public barChartLabelsEmails: Label[] = ["Email Enviados"];
    public barChartTypeAccount: ChartType = 'bar';
    public barChartLegendAccount = false;
    public quantTotalAccount: number;

    constructor(private authService: AuthService,
                private spinnerService: SpinnerService,
                private homeService: HomeService,
                private adminService: ApiAdminService,
                private toastyService: ToastyService) {}

    ngOnInit() {
      if (this.spinnerService.isVisible())
          this.spinnerService.hideSpinner();
        this.spinnerService.showSpinner(true);
        this.homeService.getDashboardInfo().subscribe((data: Dashboard[]) => {
          if (data){
            data.forEach(value => {
              if(this.barChartData[0].data.length == 0)
                this.barChartData = [{data: [value.quantidade], label: value.patente}]
              else
                this.barChartData.push({data: [value.quantidade], label: value.patente})
            });
          }
          this.spinnerService.hideSpinner();
        }, error => {
          this.spinnerService.hideSpinner();
        })
      this.getStatistics()
      this.getDashboardInfo()
    }

    onShowUpload(showUpload: boolean) {
        this.showUpload = showUpload;
    }

    onCompleteAllUpload() {
        // this.authService.updateUserSigned();


    }

    onCompleteAnyUpload(event) {
        // this.authService.updateUserSigned();


    }

    delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }

  onShowDetails($event: Array<FileView>) {
    if($event.length == 1 ) {
      this.fileSelected = $event[0];
      this.detailsSide.open();
    } else {
      this.fileSelected = new FileView();
      if(this.detailsSide)
        this.detailsSide.close();
    }
  }

  getStatistics(){
      this.homeService.getStatistics().subscribe((value) =>{
        value.forEach(statistic => {
          if(this.barChartDataAccount[0].data.length == 0)
            this.barChartDataAccount = [{data:[statistic.accountCreatedInMonth], label: this.translateMonth(statistic.statisticMonth)}]
          else
            this.barChartDataAccount.push({minBarLength: 10, data: [statistic.accountCreatedInMonth], label: this.translateMonth(statistic.statisticMonth)})
          if(this.barChartDataEmails[0].data.length == 0)
            this.barChartDataEmails = [{minBarLength: 10, data:[statistic.sentEmailInMonth], label: this.translateMonth(statistic.statisticMonth)}]
          else
            this.barChartDataEmails.push({minBarLength: 10, data: [statistic.sentEmailInMonth], label: this.translateMonth(statistic.statisticMonth)})
        })
      }, error => {
        console.log("error statistics");
        console.log(error)
      })
    console.log(this.barChartDataAccount, this.barChartLabelsAccount)
  }

  getDashboardInfo(){
      this.adminService.get("/dashboard/backupdashboard").subscribe(value => {
        this.quantTotalAccount = value.allAccounts;
      }, error => {
        this.toastyService.error("Erro ao recuperar quantidade total de usuários")
      })
  }

  translateMonth(month:string){
      switch (month){
        case "JANUARY":
          return "Janeiro";
        case "FEBRUARY":
          return "Fevereiro";
        case "MARCH":
          return "Março";
        case "APRIL":
          return "Abril";
        case "MAY":
          return "Maio";
        case "JUNE":
          return "Junho";
        case "JULY":
          return "Julho";
        case "AUGUST":
          return "Agosto";
        case "SEPTEMBER":
          return "Setembro";
        case "OCTOBER":
          return "Outubro";
        case "NOVEMBER":
          return "Novembro";
        case "DECEMBER":
          return "Dezembro";
        default: return month;
      }
  }
}

import {Component, OnInit} from "@angular/core";
import { MatTableDataSource } from '@angular/material/table';
import {AccountService} from "./account.service";
import {NotificationService} from "../../../shared/services/notification.service";
import {ToastyService} from "ng2-toasty";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {SpinnerService} from '../../../shared/spinner/spinner.service';
import {ModalBasicComponent} from '../../../shared/modal-basic/modal-basic.component';


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit{

  dataSource = new MatTableDataSource<any>();
  blockList: boolean = false;
  displayedColumns = ['name', 'email', 'action'];
  totalAccounts = 0;

  titleError: string;
  accountError: string;
  titleInvalidSearch: string;
  tryAgain: string;
  specialCharacters: string;
  searchWord = '';

  constructor(
    private notification: NotificationService,
    private toastyService: ToastyService,
    private translate: TranslateService,
    private accountService: AccountService,
    private router: Router,
    private spinnerService: SpinnerService) {

  }


  ngOnInit(): void {
    this.getInternationalizationVariable();
    this.getAccounts(10, 1);
  }


  getAccounts(pageSize: number, pageNumber: number, search?: string) {
    this.blockList = true;
    // when paginating put equal null
    this.dataSource.paginator = null;
    this.accountService.getCountAccounts(search).subscribe(
      data => {
        this.totalAccounts = data;
      }, error => {
        console.log(error);
        this.totalAccounts = 0;
      }
    );
    this.accountService.getAllAccounts(pageSize, pageNumber, search).subscribe(
      data => {
        // this.getAccountsCompanies(data);
        this.dataSource.data = data;
      },
      err => {
        this.dataSource.data = [];
        this.blockList = false;
        this.notification.notifyError(this.toastyService, this.titleError, this.tryAgain);
      }
    );
  }

  getAccountsCompanies(accounts) {
    const tempAccounts = [];
    accounts.forEach(account => {
      account.company = {};
      if (account.userHasGroups && account.userHasGroups.length > 0) {
        this.accountService.getCompanyById(account.userHasGroups[0].groupId).subscribe(
          data => {
            account.company = data;
            tempAccounts.push(account);
            if (tempAccounts.length === accounts.length) {
              this.dataSource.data = tempAccounts;
              this.blockList = false;
            }
          },
          err => {
            // just left company blank
            tempAccounts.push(account);
            if (tempAccounts.length === accounts.length) {
              this.dataSource.data = tempAccounts;
              this.blockList = false;
            }
          }
        );
      } else {
        tempAccounts.push(account);
      }
    });
    if (tempAccounts.length === accounts.length) {
      this.dataSource.data = tempAccounts;
      this.blockList = false;
    }
  }

  onChangePageListener(value) {
    // if (!this.isSearch && !this.isUpdating) {
      this.getAccounts(value.pageSize, value.pageIndex + 1);
    // } else if (this.isSearch && !this.isUpdating) {
    //   this.getAccounts(value.pageSize, value.pageIndex + 1, this.searchWord);
    // }
  }

  editAccount(id) {
    this.router.navigate(['user','form', id.toString()]);
  }

  removeAccount(id: any) {

  }

  getInternationalizationVariable(): void {
    this.translate.get('home')
      .subscribe(i => {
        this.titleError = i.error_server;
        this.accountError = i.account_error;
        this.tryAgain = i.error_try_again_few_moments;
        this.titleInvalidSearch = i.error_invalid_search;
        this.specialCharacters = i.error_special_character;
      });
  }
  searchOn() {
    this.getAccounts(10, 1, this.searchWord);
  }

  isAdmin(fullname: string) {
    return !!(fullname =='admin' || fullname =='selfhealing' || fullname == 'manager')
  }
}

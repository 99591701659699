import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ApiService} from "../../shared/services/api.service";
import {map} from "rxjs/operators";
import {ApiZimbraService} from "../../shared/services/api-zimbra.service";


@Injectable()
export class HomeService {

  private searchSubject = new BehaviorSubject<string>('');

  public search = this.searchSubject.asObservable();

  constructor(private apiService: ApiService, private apiZimbraService: ApiZimbraService) { }

  doSearch(file: string) {
    this.searchSubject.next(file);
  }

  clean() {
    this.searchSubject.next('');
  }

  getUser() {
    return this.apiService.get('/api/users/currentUser').pipe(map(
      data => {
        const user: any = {
          'email': data.email,
          'fullname': data.fullname,
          'login': data.login,
          'id': data.id,
          'companyId': data.companyId,
          'usedSpace': data.usedSpace
        };
        return user;
      }
    ));
  }

  getDashboardInfo(){
    return this.apiZimbraService.get('/api/dashboard');
  }

  getStatistics(){
    return this.apiService.get("/api/statistic/all");
  }

}

import {Component, Input, OnDestroy, Inject, ViewEncapsulation} from '@angular/core';
// import {Spinkit} from './spinkits';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: [
        './spinner.component.scss'
        // './spinkit-css/sk-line-material.scss'
    ],
    encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnDestroy {
    @Input() isSpinnerVisible = false;
    @Input() isLoadingVisible = true;

    /*
    public Spinkit = Spinkit;
    @Input() public backgroundColor = 'rgba(51, 155, 135, 0.8)';
    @Input() public spinner = Spinkit.skLine;
    */
    constructor() {
    }

    ngOnDestroy() {

    }

}

import { Injectable } from '@angular/core';
import { User } from '../domains/user';


@Injectable()
export class UserService {

  getToken(): string {
    /* if (window.localStorage['user']) {
      return JSON.parse(window.localStorage['user']).code;
    } */
    if (window.localStorage['cb-auth']) {
      return window.localStorage['cb-auth'];
    }
    return null;
  }

  getUser(): User {
    if (window.localStorage['user']) {
      return JSON.parse(window.localStorage['user']);
    }
    return null;
  }

  saveUser(user: User) {
    window.localStorage['user'] = JSON.stringify(user);
  }

  destroyUser() {
    window.localStorage.removeItem('user');
  }

  saveToken(token: string) {
    window.localStorage['cb-auth'] = token;
  }

  saveLicense(license: any) {
    window.localStorage['user-license'] = JSON.stringify(license);
  }

  getLicense() {
    if (window.localStorage['user-license']) {
      return JSON.parse(window.localStorage['user-license']);
    }
    return null;
  }

  saveUserCompany(company: any) {
    window.localStorage['user-company'] = JSON.stringify(company);
    this.saveProfile(company.type.toLowerCase());
  }

  saveProfile(companyType: string) {
    const user = JSON.parse(window.localStorage['user']);
    switch (companyType) {
      case 'distributor':
        user.profile = 'distribuidor';
        break;
      case 'resale':
        user.profile = 'revendedor';
        break;
      case 'client':
        user.profile = 'cliente';
        break;
      case 'safelatina':
        user.profile = 'safelatina';
        break;
    }
    this.saveUser(user);
  }

  saveS3Info(data: any) {
    window.localStorage['s3-auth-info'] = JSON.stringify(data);
  }

  getS3Info() {
    if (window.localStorage['s3-auth-info']) {
      return JSON.parse(window.localStorage['s3-auth-info']);
    }
    return null;
  }

  destroyAll() {
    window.localStorage.removeItem("s3-auth-info");
    window.localStorage.removeItem("user-license");
    window.localStorage.removeItem("user-company");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("cb-auth");
  }
}

import { Input, Component, ViewChild, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastyService } from 'ng2-toasty';

import { AuthService } from '../../../../shared/services/auth.service';
import { UserService } from '../../../../shared/services/user.service';
import { User } from '../../../../shared/domains/user';
import { NotificationService } from '../../../../shared/services/notification.service';
import { ModalBasicComponent } from '../../../../shared/modal-basic/modal-basic.component';
import { ChangeDataService } from './change-data.service';
import { SpinnerService } from '../../../../shared/spinner/spinner.service';

@Component({
  selector: 'app-change-data',
  templateUrl: './change-data.component.html',
  styleUrls: ['./change-data.component.scss',
  '../../../../../../node_modules/ng2-toasty/style-bootstrap.css',
  '../../../../../../node_modules/ng2-toasty/style-default.css',
  '../../../../../../node_modules/ng2-toasty/style-material.css'],
  encapsulation: ViewEncapsulation.None
})
export class ChangeDataComponent {
  public user: User;

  @ViewChild('modalChangeData') modalBasic: ModalBasicComponent;
  @Output() changedUserInfoEvent = new EventEmitter<User>();

  /**
   * inputs values
   */
  userNameValue: string = null;
  emailValue: string = null;

  /**
   *  internationalization variable
   */
  enterUserNameFailed: string;
  enterEmailFailed: string;
  enterDataIsEmpty: string;
  titleError: string;
  requestSend: string;
  changedData: string;
  titleInvalid: string;
  enterEmailInvalid: string;
  invalidData: string;
  tryAgainFewMoments: string;
  titleServerError: string;

  constructor(private translate: TranslateService,
              private notification: NotificationService,
              private toastyService: ToastyService,
              private authService: AuthService,
              public spinnerService: SpinnerService,
              private changeDataService: ChangeDataService,
              private userService: UserService) {
  }

  public show(): void {
    this.user = this.authService.getUserSigned();
    this.userNameValue = this.user.fullname;
    this.emailValue = this.user.email;
    this.getInternationalizationVariable();
    this.modalBasic.show();
  }

  public hide(): void {
    this.modalBasic.hide();
  }

  /**
   * get internationalization from i18n
   */
  getInternationalizationVariable(): void {
    this.translate.get('change-data')
    .subscribe(i => {
      this.enterUserNameFailed = i.error_enter_username;
      this.enterEmailFailed = i.error_enter_email;
      this.enterDataIsEmpty = i.error_data_empty;
      this.titleError = i.error_missing_data;
      this.titleInvalid = i.error_invalid_data;
      this.requestSend = i.request_send;
      this.changedData = i.changed_data;
      this.enterEmailInvalid = i.error_invalid_email;
      this.invalidData = i.error_invalid_changedata;
      this.titleServerError = i.error_server;
      this.tryAgainFewMoments = i.error_try_again_few_moments;
    });
  }

  /**
   * When the inputs are empty
   * @param username input value username
   * @param email input value email
   */
  notifyErrorWhenDataEmpty() {
    if (this.isEmpty(this.userNameValue) && this.isEmpty(this.emailValue)) {
      this.notification.notifyError(this.toastyService, this.titleError, this.enterDataIsEmpty);
    }else if (this.isEmpty(this.userNameValue)) {
      this.notification.notifyError(this.toastyService, this.titleError, this.enterUserNameFailed);
    }else if (this.isEmpty(this.emailValue)) {
      this.notification.notifyError(this.toastyService, this.titleError, this.enterEmailFailed);
    } else {
      this.notification.notifyError(this.toastyService, this.titleInvalid, this.enterEmailInvalid);
    }
  }
  isEmpty(str) {
    return (!str || 0 === str.length);
  }

  doChangeData() {
    if (this.user.fullname === this.userNameValue
        && this.user.email === this.emailValue) {
      this.notification.notifySuccess(this.toastyService, this.requestSend, this.changedData);
      this.hide();
      return;
    }

    this.spinnerService.showSpinner(true);

    this.changeDataService.updateData(this.user.login, this.emailValue, this.userNameValue).subscribe(
      data => {
        this.notification.notifySuccess(this.toastyService, this.requestSend, this.changedData);
        this.user.fullname = this.userNameValue;
        this.user.email = this.emailValue;
        this.userService.saveUser(this.user);
        this.changedUserInfoEvent.emit(this.user);
        this.spinnerService.hideSpinner();
        this.hide();
      },
      err => {
        this.spinnerService.hideSpinner();

        if (err.message === this.changeDataService.ERROR_INVALID_DATA) {
          this.notification.notifyError(this.toastyService, this.titleInvalid, this.invalidData);
        } else {
          this.notification.notifyError(this.toastyService, this.titleServerError, this.tryAgainFewMoments);
        }
      }
    );

  }
}

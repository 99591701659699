
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';



import { UserService } from './user.service';

@Injectable()
export class ApiService {

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {}

  /*
   *@desc function to set the header in every request
   *
  */
  private setHeaders(header: Map<string, string>): HttpHeaders {

    const isIE11 = document['documentMode'];

    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };

    if (isIE11) {
      headersConfig['If-Modified-Since'] = `${'Sat, 01 Jan 2000 00:00:00 GMT'}`;
      headersConfig['Pragma'] = `${'no-cache'}`;
      headersConfig['Expires'] = `${'Sat, 01 Jan 2000 00:00:00 GMT'}`;
    }


    if (this.userService.getToken()) {
      headersConfig['token'] = `${this.userService.getToken()}`;
    }
    if (header) {
      header.forEach((value, key) => {
        headersConfig[key] = value;
      });
    }
    return new HttpHeaders(headersConfig);
  }

  private formatErrors(error: any) {
    return observableThrowError(error);
  }

  get(path: string, header?: Map<string, string> ): Observable<any> {
    return this.http.get(`${environment.api_zimbra}${path}`, {headers: this.setHeaders(header)})
      .pipe(map((res: HttpResponse<Response>) => res), catchError(this.formatErrors));
  }

  getFile(path: string, header?: Map<string, string> ): Observable<any> {
    const req = new HttpRequest('GET', `${environment.api_zimbra}${path}`, {headers: this.setHeaders(header),
        reportProgress: true, responseType: 'blob'});
    return this.http.request(req)
      .pipe(map((res: HttpResponse<Response>) => res), catchError(this.formatErrors));
    /*return this.http.get(`${environment.api_zimbra}${path}`, {headers: this.setHeaders(header),
        reportProgress: true, responseType: 'blob'})
    .catch(this.formatErrors)
    .map((res: HttpResponse<Response>) => res);*/
  }


  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.api_zimbra}${path}`,
      JSON.stringify(body),
      {headers: this.setHeaders(null)}
    )
      .pipe(map((res: HttpResponse<Response>) => res), catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}, header?: Map<string, string>): Observable<any> {

    return this.http.post(
      `${environment.api_zimbra}${path}`,
      JSON.stringify(body),
      {headers: this.setHeaders(header)}
    )
      .pipe(map((res: HttpResponse<Response>) => res), catchError(this.formatErrors));
  }



  delete(path): Observable<any> {
    return this.http.delete(
      `${environment.api_zimbra}${path}`,
      {headers: this.setHeaders(null)}
    )
      .pipe(map((res: HttpResponse<Response>) => res), catchError(this.formatErrors));
  }

}

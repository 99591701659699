export const config = {
  "ustore": {
      "corp": "Ustore ©",
      "title": "Portal Admin"
  },
  "eb": {
      "corp": "EBDrive ©",
      "title": "Portal Admin"
  },
  "safelatina": {
      "corp": "Safelatina ©",
      "title": "Portal Admin"
    },
  "safeorange": {
    "corp": "Safeorange ©",
    "title": "Portal Admin"
  },
  "embratel": {
    "corp": "Embratel ©",
    "title": "Portal Admin"
  }
}

<div style="height: 90vh; overflow-y: auto">
  <button id="btnConfirmNow" type="button" class="btn btn-primary waves-effect waves-light"
          (click)="doBackup()" style="margin-bottom: 10px">
    Fazer Backup agora
  </button>
  <div class="card">
    <div class="card-body">

      <div style="text-align:center">
        <apx-chart
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [xaxis]="chartOptions.xaxis"
          [title]="chartOptions.title"
        ></apx-chart>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      <h4 class="modal-title">Configurar agendamento de backup de emails</h4>
    </div>
    <div class="card-body">
      <div>
        <div>
          <form #schedulingForm="ngForm">
            <div class="row">
              <h5>Completo</h5>
            </div>
            <div class="row">
              <p>Um backup completo por semana no(a)</p>
              <select [(ngModel)]="schedule.full.day" name="dayOfWeek" class="input-scheduler">
                <option *ngFor="let day of daysOfWeek" [value]="day.value">{{day.label}}</option>
              </select>
              <p>ás</p>
              <input type="time" id="appt" name="full-hour"
                     min="00:00" max="23:59" required [(ngModel)]="schedule.full.hour" class="input-scheduler">
              <p>horas</p>
            </div>

            <br>
            <div class="div-line"></div>
            <div class="row" style="margin-top: 20px;">
              <h5>Incremental</h5>
            </div>
            <div class="row">
              <p>Dias</p>
              <ul style="padding-left: 10px; padding-right: 10px;">
                <li *ngFor="let day of daysOfWeek" style="float: left; padding-left: 5px; padding-right: 5px;">
                  <input type="checkbox" name="check-{{day.label}}" [(ngModel)]="day.check"> - {{day.label}}
                </li>
              </ul>
              <p>A partir da(s)</p>
              <input type="time" id="appt2" name="incremental-hour"
                     min="00:00" max="23:59" required [(ngModel)]="schedule.incremental.hour" class="input-scheduler">
            </div>
          </form>
        </div>
        <div class="div-line" style="margin-top: 20px;"></div>
        <div style="margin-top: 20px;">
          <button id="btnConfirm" type="button" class="btn btn-primary waves-effect waves-light"
                  (click)="(schedulingForm.valid) ? doScheduleBackup() : notifyFormError()">
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng2-toasty></ng2-toasty>


import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from './shared/spinner/spinner.service';
import {Title} from "@angular/platform-browser";
import {StringsService} from "./shared/services/strings.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private router: Router, private translate: TranslateService,
    public spinnerService: SpinnerService, private title: Title, private strings: StringsService) {
      translate.setDefaultLang('en');
      title.setTitle(strings.from("title"))
  }

}

import { Component, ViewChild, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastyService } from 'ng2-toasty';
import { Router } from '@angular/router';

import { AuthService } from '../../../../shared/services/auth.service';
import { ModalBasicComponent } from '../../../../shared/modal-basic/modal-basic.component';
import { SpinnerService } from '../../../../shared/spinner/spinner.service';

@Component({
  selector: 'app-resend-email',
  templateUrl: './resend-email.component.html',
  styleUrls: ['./resend-email.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResendEmailComponent {

  @ViewChild('modalResendEmail') modalBasic: ModalBasicComponent;
  @Output() resendEvent = new EventEmitter<boolean>();

  /**
   *  internationalization variable
   */
  enterOldPasswordFailed: string;
  enterNewPasswordFailed: string;
  enterConfirmNewPasswordFailed: string;
  enterPasswordIsEmpty: string;
  titleError: string;
  requestSend: string;
  changedPassword: string;
  titleInvalid: string;
  enterValidPassword: string;
  passwordInvalidSize: string;
  titleSuccess: string;
  passwordChange: string;
  invalidData: string;
  tryAgainFewMoments: string;
  titleServerError: string;

  constructor(private translate: TranslateService,
              private toastyService: ToastyService,
              private router: Router,
              private authService: AuthService,
              public spinnerService: SpinnerService) {
  }

  public show(): void {
    this.getInternationalizationVariable();
    this.modalBasic.show();
  }

  public hide(): void {
    this.modalBasic.hide();
  }

  /**
   * get internationalization from i18n
   */
  getInternationalizationVariable(): void {
    this.translate.get('resend-email')
    .subscribe(i => {
      this.enterOldPasswordFailed = i.error_enter_oldpassword;
      this.enterNewPasswordFailed = i.error_enter_newpassword;
      this.enterConfirmNewPasswordFailed = i.error_enter_confirm_newpassword;
      this.enterPasswordIsEmpty = i.error_password_empty;
      this.titleError = i.error_missing_password;
      this.titleInvalid = i.error_invalid_password;
      this.requestSend = i.request_send;
      this.enterValidPassword = i.error_valid_password;
      this.passwordInvalidSize = i.error_password_size;
      this.titleSuccess = i.password_change;
      this.passwordChange = i.success_password;
      this.invalidData = i.error_invalid_data;
      this.titleServerError = i.error_server;
      this.tryAgainFewMoments = i.error_try_again_few_moments;
    });
  }

  doResendEmail() {
    this.hide();
    setTimeout ( () => {
      this.resendEvent.emit(true);
    }, 100);
  }

  goToLogin() {
    this.router.navigate(['login']);
  }

}

import {Observable, BehaviorSubject} from 'rxjs';
import {Injectable} from '@angular/core';
import {User} from '../domains/user';
import {ApiService} from './api.service';
import {UserService} from './user.service';
import {environment} from "../../../environments/environment";
import {map} from "rxjs/operators";


@Injectable()
export class AuthService {

  readonly ERROR_INVALID_USER = 'BAD_LOGIN';
  readonly ERROR_INACTIVE_USER = 'EXPIRED_USER';

  private userSubject: BehaviorSubject<User>;
  public userUpdated: Observable<User>;

  constructor(private apiService: ApiService, private userService: UserService) {
    this.userSubject = new BehaviorSubject<User>(this.getUserSigned());
    this.userUpdated = this.userSubject.asObservable();
  }

  login(user: string, password: string): Observable<User> {
    const header = new Map();
    header.set("AuthType", environment.auth_type);

    const body = {
      login: user,
      password: password
    };

    return this.apiService.post('/api/auth', body, header).pipe(map(
      data => {
        //this.userService.saveUser(data);
        this.userService.saveToken(data.token);
        return data;
      }
    ));
  }


  logout(): Observable<any> {
    this.userService.destroyAll();
    return this.apiService.post('/api/logout', {}).pipe(map(
      data => {
        return data;
      }
    ));
  }

  isSignedIn(): boolean {
    if (this.userService.getUser()) {
      return true;
    }
    return false;
  }

  getUserSigned(): User {
    if (this.isSignedIn()) {
      return this.userService.getUser();
    }
    return null;
  }

  updateUserSigned() {
    this.apiService.get('/user/me').subscribe(
      data => {
        const user = this.userService.getUser();
        user.usedSpace = data.usedSpace;
        user.maxSpace = data.maxSpace;
        this.userService.saveUser(user);
        this.userSubject.next(this.getUserSigned());
      }
    );
  }

  getUserInfo() {
    return this.apiService.get('/api/users/currentUser').pipe(map(
      data => {
        const user: any = {
          'code': this.userService.getToken(),
          'email': data.email,
          'fullname': data.fullname,
          'login': data.login,
          'id': data.id,
          'companyId': data.companyId,
          'usedSpace': data.usedSpace
        };
        this.userService.saveUser(user);
        return user.companyId;
      }
    ));
  }

  getLicenseByCompanyId(companyId: string) {
    const header = new Map();
    header.set('id', companyId);
    return this.apiService.get('/licenses/getByCompanyId', header).pipe(map(
      data => {
        this.userService.saveLicense(data.hash);
        return data;
      }
    ));
  }

  getUserCompany(companyId: string) {
    const header = new Map();
    header.set('id', companyId);
    return this.apiService.get('/companies', header).pipe(map(
      data => {
        this.userService.saveUserCompany(data);
        return data;
      }
    ));
  }

  verifyFirstAccess(login: any) {
    const header = new Map();
    header.set('login', login);
    return this.apiService.get('/api/verifyFirstAccess', header).pipe(map(
      data => {
        return data;
      }
    ));
  }

}

import {Injectable} from "@angular/core";
import {ApiZimbraService} from "../../../shared/services/api-zimbra.service";

@Injectable()
export class ZimbraService {


  static PATH = "/api/zm";
  static PATH_VPN = "/api/zm/vpn";
  static PATH_VPN_HAVE_ACCESS = '/api/zm/vpn/haveaccess';

  constructor(private apiZimbraService: ApiZimbraService) {
  }

  getListUser(search?){
    const header = new Map();
    header.set("search", search ? search : "eb.mil.br");
    return this.apiZimbraService.get(ZimbraService.PATH,header );
  }

  getListVpn(page, pageSize){
    const header = new Map();
    header.set('page', page.toString());
    header.set('pageSize', pageSize.toString());
    return this.apiZimbraService.get(ZimbraService.PATH_VPN, header);
  }

  alterVpnAccess(email, status){
    const body = {
      email: email,
      status: status
    };
    return this.apiZimbraService.put(ZimbraService.PATH_VPN, body);
  }
  getListVpnHaveAccess(page, pageSize, searchWord?) {
    if (searchWord) {
      const header = new Map();
      header.set('search', searchWord);
      header.set('page', page.toString());
      header.set('pageSize', pageSize.toString());

      return this.apiZimbraService.get(ZimbraService.PATH_VPN_HAVE_ACCESS, header);
    }
    else {
      const header = new Map();
      header.set('page', page.toString());
      header.set('pageSize', pageSize.toString());
      return this.apiZimbraService.get(ZimbraService.PATH_VPN_HAVE_ACCESS, header);
    }
  }

}

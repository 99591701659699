<section class="login p-fixed d-flex text-center bg-primary common-img-bg"  style="background: url(./assets/images/login_background.png) no-repeat center center fixed">
  <!-- Container-fluid starts -->
  <div class="container" >
    <div class="row">
      <div class="col-sm-12">
        <!-- Authentication card start -->
        <div class="login-card card-block auth-body mr-auto ml-auto">
          <div class="md-float-material" >
            <div class="text-center">
              <img src="assets/images/logo_login.png" height="144.06px" width="158px" alt="Gradient Able">
            </div>
            <form class="auth-box" #loginForm="ngForm">

              <div class="row m-b-20 close">
                <div class="col-md-12">
                  <a [routerLink]="['/', 'login']"><img src="assets/images/close.png" height="14px" width="15px"/></a>
                </div>
              </div>
              <div class="row m-b-20 m-close">
                <div class="col-md-12">
                  <h3 class="text-center txt-primary txt-upper">{{'recover.title' | translate}}</h3>
                </div>
              </div>

              <div class="input-password">
                <div style="position: relative;">
                  <input required minlength="8" ngClass="{{idx}}" type="password" class="form-control" placeholder="{{'change-password.newPassword' | translate}}"
                    name="password" (keyup)="watchPass($event)" #password="ngModel" [(ngModel)]="newPasswordValue">
                  <i class="icon-info-pass"  placement="right"  ngbTooltip="{{'recover.tooltip' | translate}}"></i>
                </div>
                <small ngClass="{{classMsgPassword}}">{{idxMessage}}</small>
              </div>

              <div class="input-group">
                <input type="password" (keyup.enter)="(loginForm.valid) ? doRecover(password.value, passwordConfirm.value) : notifyErrorWhenCredentialEmpty(password.value, passwordConfirm.value)" required class="form-control" name="passwordConfirm" placeholder="{{'login.passwordConfirm' | translate}}" #passwordConfirm="ngModel" ngModel>
                <span class="md-line"></span>
              </div>
              <div class="row m-t-30">
                <div class="col-md-12">
                  <button (click)="(loginForm.valid) ? doRecover(password.value, passwordConfirm.value) : notifyErrorWhenCredentialEmpty(password.value, passwordConfirm.value)"
                    type="button" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20" [disabled]="block">{{'recover.change' | translate}}</button>
                </div>
              </div>
              <hr/>
              <div>
                <app-version></app-version>
              </div>
            </form>
          </div>
          <!-- end of form -->
        </div>
        <!-- Authentication card end -->
      </div>
      <!-- end of col-sm-12 -->
    </div>
    <!-- end of row -->
  </div>
  <!-- end of container-fluid -->
</section>
<ng2-toasty></ng2-toasty>

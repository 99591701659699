<section class="login p-fixed d-flex text-center bg-primary common-img-bg">
  <!-- Container-fluid starts -->
  <div class="container" >
    <div class="row">
      <div class="col-sm-12">
        <!-- Authentication card start -->
        <div class="login-card card-block auth-body mr-auto ml-auto">
          <div class="md-float-material" >
            <div class="text-center">
              <img src="assets/images/logo_login.png" height="144.06px" width="158px" alt="Gradient Able">
            </div>
            <form class="auth-box" #loginForm="ngForm">
              <!-- Icon close -->
              <div class="row m-b-20 close">
                <div class="col-md-12">
                  <a [routerLink]="['/', 'login']"><img src="assets/images/close.png" height="14px" width="15px"/></a>
                </div>
              </div>
              <div class="row m-b-20 m-close">
                <div class="col-md-12">
                  <h3 class="text-center txt-primary txt-upper">{{'forgot.first-access.title' | translate}}</h3>
                </div>
                <label class="text-center recover-sub-title small">{{'forgot.first-access.sub_title' | translate}}</label>
              </div>
              <!-- End of icon close -->

              <div class="input-group">
                <input  type="email" (keyup.enter)="(loginForm.valid) ? doRequest(email.value) : notifyErrorWhenCredentialEmpty(email.value)" class="form-control" name="email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  placeholder="{{'forgot.e-mail' | translate}}" #email="ngModel" ngModel>
                <span class="md-line"></span>
              </div>
              <div class="row m-t-30">
                <div class="col-md-12">
                  <button (click)="(loginForm.valid) ? doRequest(email.value) : notifyErrorWhenCredentialEmpty(email.value)" type="button" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">{{'forgot.request' | translate}}</button>
                </div>
              </div>
              <hr/>
              <div>
                <app-version></app-version>
              </div>
            </form>
          </div>
          <!-- end of form -->
        </div>
        <!-- Authentication card end -->
      </div>
      <!-- end of col-sm-12 -->
    </div>
    <!-- end of row -->
  </div>
  <!-- end of container-fluid -->
</section>
<ng2-toasty></ng2-toasty>
<!-- resend email modal -->
<app-resend-email #modalResendEmail (resendEvent)="doRequest(email.value)"></app-resend-email>

import {Injectable} from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'home.navigation',
    main: [
      {
        state: '/',
        short_label: 'N',
        name: 'home.title',
        type: 'link',
        icon: 'icon-cb-home'
      },
      {
        state: 'user',
        short_label: 'U',
        name: 'user.title',
        type: 'sub',
        icon: 'icon-cb-user',
        children: [
          {
            state: 'form',
            name: 'user.title-new'
          },
          {
            state: 'office',
            name: 'user.title-new-office'
          },
          {
            state: 'list',
            name: 'user.title-list'
          },
          {
            state: 'list-office',
            name: 'Listar Usuários Oficiais'

          }
        ]
      },
      {
        state: 'vpn',
        short_label: 'V',
        name: 'vpn.title',
        type: 'sub',
        icon: 'icon-cb-vpn',
        children: [
          {
            state: 'permission',
            name: 'vpn.request'
        },
          {
            state: 'access',
            name: 'vpn.revoke'
          }
        ]
      },
      {
        state: 'backup-scheduler',
        short_label: 'B',
        name: 'backup_scheduler.title',
        type: 'link',
        icon: 'icon-cb-backup'
      },
      {
        state: 'restore',
        short_label: 'R',
        name: 'restore.title',
        type: 'link',
        icon: 'icon-cb-restore'
      }
    ],
  }
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
}

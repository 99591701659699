import { Component, ViewChild, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastyService } from 'ng2-toasty';
import {ModalBasicComponent} from '../../../shared/modal-basic/modal-basic.component';
import {AccountService} from '../account/account.service';
import {SpinnerService} from '../../../shared/spinner/spinner.service';

@Component({
  selector: 'app-confirm-model',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss',
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'],
  encapsulation: ViewEncapsulation.None
})

export class ConfirmComponent {

  @ViewChild('modalconfirmComponent') modalBasic: ModalBasicComponent;
  @Output() removedAccountsEvent = new EventEmitter<string>();

  accounts = [];

  // internationalization variable

  requestSend: string;
  removedAccounts: string;
  removedSomeAccounts: string;
  tryAgainFewMoments: string;
  titleServerError: string;
  titleWithParents: string;
  removeWithParents: string;
  id;
  email: string;
  block: boolean;
  fullname: string;

  constructor(
    private translate: TranslateService,
    private toastyService: ToastyService,
    public spinnerService: SpinnerService,
    private accountService: AccountService) { }

  public show(id , email, fullname, block): void {
    this.email = email;
    this.id = id;
    this.block = block;
    this.fullname = fullname;

    console.log(this.block);
    this.getInternationalizationVariable();
    this.modalBasic.show();
  }

  public hide(): void {
    this.modalBasic.hide();
  }

  // get internationalization from i18n
  getInternationalizationVariable(): void {
    this.translate.get('remove-account')
      .subscribe(i => {
        this.requestSend = i.request_send;
        this.removedAccounts = i.removed_accounts;
        this.removedSomeAccounts = i.removed_some_accounts;
        this.titleServerError = i.error_server;
        this.tryAgainFewMoments = i.error_try_again_few_moments;
        this.titleWithParents = i.error_title_account_with_parents;
        this.removeWithParents = i.error_remove_with_parents;
      });
  }
  // doRemoveAccounts() {
  //   this.spinnerService.showSpinner(true);
  //
  //   let removedCount = this.accounts.length;
  //
  //   let failedRemovedCount = 0;
  //
  //   this.accounts.forEach(account => {
  //     this.accountService.excludeAccount(account.id).subscribe(
  //       data => {
  //         removedCount--;
  //         this.checkNotification(this.accounts.length, removedCount, failedRemovedCount);
  //       },
  //       err => {
  //         failedRemovedCount++;
  //         this.checkNotification(this.accounts.length, removedCount, failedRemovedCount, err.error.systemCode);
  //       }
  //     );
  //   });
  // }

  // checkNotification(size: number, removed: number, failed: number, systemCode?: number) {
  //   if (systemCode && systemCode === 461) {
  //     this.notification.notifyError(this.toastyService, this.titleWithParents, this.removeWithParents);
  //     // removed all
  //   } else if (removed === 0) {
  //     this.notification.notifySuccess(this.toastyService, this.requestSend, this.removedAccounts);
  //     this.removedAccountsEvent.emit();
  //     this.spinnerService.hideSpinner();
  //     this.hide();
  //     // failed all
  //   } else if (failed === size) {
  //     this.spinnerService.hideSpinner();
  //     this.notification.notifyError(this.toastyService, this.titleServerError, this.tryAgainFewMoments);
  //     // some failed
  //   } else if (removed === failed) {
  //     this.notification.notifyError(this.toastyService, this.requestSend, this.removedSomeAccounts);
  //     this.removedAccountsEvent.emit();
  //     this.spinnerService.hideSpinner();
  //     this.hide();
  //   }
  // }

  onConfirm() {
    this.spinnerService.showSpinner(true);
    if (!this.block) {
      this.accountService.deactivateUser(this.id, this.email).subscribe(data => {
        this.removedAccountsEvent.emit();
        this.hide();
        this.spinnerService.hideSpinner();
        this.toastyService.success("Usuário desativado com sucesso")
      }, error => {
        this.toastyService.error("Erro ao desativar usuário")
        this.hide()
        this.spinnerService.hideSpinner();
      });
    } else {
      this.accountService.activateUser(this.id, this.email).subscribe(data => {
        this.removedAccountsEvent.emit();
        this.spinnerService.hideSpinner();
        this.hide();
        this.toastyService.success("Usuário ativado com sucesso")

      }, error => {
        this.spinnerService.hideSpinner();
        this.hide()
        this.toastyService.error("Erro ao ativar usuário")
      });
    }
  }
}

import {Component, OnInit} from "@angular/core";
import { MatTableDataSource } from '@angular/material/table';
import {ToastyService} from "ng2-toasty";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {SpinnerService} from '../../../../shared/spinner/spinner.service';
import {NotificationService} from '../../../../shared/services/notification.service';
import {AccountService} from '../account.service';



@Component({
  selector: 'list-user-office',
  templateUrl: 'list-user-office.component.html',
  styleUrls: ['./list-user-office.component.scss']
})
export class ListUserOfficeComponent implements OnInit{

  dataSource = new MatTableDataSource<any>();
  blockList: boolean = false;
  displayedColumns = ['name', 'email', 'action'];
  totalAccounts = 0;

  titleError: string;
  accountError: string;
  titleInvalidSearch: string;
  tryAgain: string;
  specialCharacters: string;
  searchWord = '';

  constructor(
    private notification: NotificationService,
    private toastyService: ToastyService,
    private translate: TranslateService,
    private accountService: AccountService,
    private router: Router,
    private spinnerService: SpinnerService) {

  }


  ngOnInit(): void {
    this.getInternationalizationVariable();
    this.getAccounts(1,10);
  }


  getAccounts(page, pageSize, searchWord?) {
    this.accountService.getOfficeAccounts(page,pageSize, searchWord? searchWord: null).subscribe(
      data => {
        console.log("resposta", data);
        this.dataSource.data = data.content;
        this.totalAccounts = data.numberOfElements
      },
      err => {
        this.dataSource.data = [];
        this.blockList = false;
        this.notification.notifyError(this.toastyService, this.titleError, this.tryAgain);
      }
    );
  }

  // getAccountsCompanies(accounts) {
  //   const tempAccounts = [];
  //   accounts.forEach(account => {
  //     account.company = {};
  //     if (account.userHasGroups && account.userHasGroups.length > 0) {
  //       this.accountService.getCompanyById(account.userHasGroups[0].groupId).subscribe(
  //         data => {
  //           account.company = data;
  //           tempAccounts.push(account);
  //           if (tempAccounts.length === accounts.length) {
  //             this.dataSource.data = tempAccounts;
  //             this.blockList = false;
  //           }
  //         },
  //         err => {
  //           // just left company blank
  //           tempAccounts.push(account);
  //           if (tempAccounts.length === accounts.length) {
  //             this.dataSource.data = tempAccounts;
  //             this.blockList = false;
  //           }
  //         }
  //       );
  //     } else {
  //       tempAccounts.push(account);
  //     }
  //   });
  //   if (tempAccounts.length === accounts.length) {
  //     this.dataSource.data = tempAccounts;
  //     this.blockList = false;
  //   }
  // }

  onChangePageListener(value) {
    console.log("onChangePageListener", value);
    // if (!this.isSearch && !this.isUpdating) {
      this.getAccounts( value.pageIndex + 1, value.pageSize);
    // } else if (this.isSearch && !this.isUpdating) {
    //   this.getAccounts(value.pageSize, value.pageIndex + 1, this.searchWord);
    // }
  }

  searchOn() {
  this.getAccounts(1,10,this.searchWord)
  }


  removeAccount(id: any) {

  }

  getInternationalizationVariable(): void {
    this.translate.get('home')
      .subscribe(i => {
        this.titleError = i.error_server;
        this.accountError = i.account_error;
        this.tryAgain = i.error_try_again_few_moments;
        this.titleInvalidSearch = i.error_invalid_search;
        this.specialCharacters = i.error_special_character;
      });
  }

}

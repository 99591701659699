<div id="input-search" class="input-group" title="{{ 'vpn.placeholder' | translate }}" >
  <input id="searchBar" (keyup.enter)="searchOn()" [(ngModel)]="searchWord" placeholder="{{ 'vpn.placeholder' | translate }}"/>
  <span   class="search-btn" (click)="searchOn()"><i class="icon-search" ></i></span>
</div>
<div >
  <div class="table-container">
    <div id="http-block" *ngIf="blockList"></div>
    <mat-table #table [dataSource]="dataSource"  id="material-scroll-detect">
      <!-- Position Column -->
      <!--    <ng-container matColumnDef="id">-->
      <!--      <mat-header-cell *matHeaderCellDef class="select-table">-->
      <!--        <mat-checkbox (change)="$event ? masterToggle() : null; selectChange()"-->
      <!--                      [checked]="selection.hasValue() && isAllSelected()"-->
      <!--                      [indeterminate]="selection.hasValue() && !isAllSelected()">-->
      <!--        </mat-checkbox>-->
      <!--      </mat-header-cell>-->
      <!--      <mat-cell *matCellDef="let row" class="select-table">-->
      <!--        <mat-checkbox *ngIf="row.type !== 'PEER_MANAGER'"-->
      <!--                      (change)="$event ? selection.toggle(row) : null; selectChange()"-->
      <!--                      [checked]="selection.isSelected(row)">-->
      <!--        </mat-checkbox>-->
      <!--      </mat-cell>-->
      <!--    </ng-container>-->
<!--      barra de pesquisa-->

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell class="title-table-name" *matHeaderCellDef> {{'home.name_table' | translate}}</mat-header-cell>

        <mat-cell *matCellDef="let element" class="name-file-title">
          <div class="name-container">
            <div class="name-container-text" [ngClass]="{'disable': element.block}">{{element.fullname}}</div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Contact Column -->
      <ng-container matColumnDef="email" >
        <mat-header-cell class="title-table-email" *matHeaderCellDef> {{'home.contact_table' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element" class="email-title" [ngClass]="{'disable': element.block}">{{element.email}}</mat-cell>
      </ng-container>

      <!--    &lt;!&ndash; Company Column &ndash;&gt;-->
      <!--    <ng-container matColumnDef="company">-->
      <!--      <mat-header-cell class="title-table date-table" *matHeaderCellDef> {{'home.company_table' | translate}}</mat-header-cell>-->
      <!--      <mat-cell *matCellDef="let element" class="date-table" [ngClass]="{'disable': element.block}">-->
      <!--        <div >{{element.company.name}}</div>-->
      <!--      </mat-cell>-->
      <!--    </ng-container>-->

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <mat-header-cell class="title-table" *matHeaderCellDef> {{'home.action_table' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element">
<!--          <div *ngIf="!element.block" (click)="editAccount(element.id)" class="status" style="color: #A1A0A0">{{'general.edit' | translate}}</div>-->
          <div *ngIf="!isAdmin(element.fullname)">
            <div title="Excluir usuário permanentemente"  (click)="confirmPurgeComponent.show(element.id, element.fullname, element.email, false)" class="action-botton">Excluir</div>
            <div title="{{'user.deactivate' | translate}}" *ngIf="!element.block" (click)="confirmComponent.show(element.id, element.email, element.fullname, element.block)" class="action-botton">{{'general.deactivate' | translate}}</div>
            <div title="{{'user.activate' | translate}}" *ngIf="element.block" (click)="confirmComponent.show(element.id, element.email, element.fullname, element.block)"  class="action-botton">{{'general.activate' | translate}}</div>
          </div>
        </mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row class="list-item"  *matRowDef="let row; columns: displayedColumns;"
      ></mat-row>

    </mat-table>

    <mat-paginator (page)="onChangePageListener($event)" #paginator [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 20]" [length]="totalAccounts" >
    </mat-paginator>

    <div class="row text-center mt-4 no-itens-found" *ngIf="dataSource.data.length == 0">
      <div class="col-sm-12">
        <a class="no-item">
          {{ 'home.empty_search' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>

<ng2-toasty></ng2-toasty>
<app-confirm-model #confirmComponent (removedAccountsEvent)="getAccounts(10,1)"></app-confirm-model>
<app-confirm-modal-purge-user #confirmPurgeComponent (removedAccountsEvent)="getAccounts(10,1)"></app-confirm-modal-purge-user>

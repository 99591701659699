import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {UserService} from "./user.service";
import { environment } from "../../../environments/environment";

@Injectable()
export class PrivatePageGuard implements CanActivate {

  url = environment.api_url;

  constructor(private router: Router,
              private authService: AuthService,
              //private http: HttpClient,
              //private userService: UserService
              ) {}

  canActivate() {
    if (!this.authService.isSignedIn()) {
      this.router.navigate(['/login']);
    }
    return this.authService.isSignedIn();
  }

  /* canActivate1(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | boolean {
    let user = route.queryParams.code;
    console.log(user);
    if(user) {
      return this.login(user).map(
        data => {
          console.log(data);
          if(data) {
            this.router.navigate(['']);
            return true;
          }
        }, err => {
          this.router.navigate(['/login']);
        }
      );
    } else {
      if (!this.authService.isSignedIn()) {
        this.router.navigate(['/login']);
      }
      return this.authService.isSignedIn();
    }

    // return this.loginService.logado()
    //   .map(
    //     data => {
    //       if (data == false) {
    //         this.router.navigate(['/auth']);
    //         return data;
    //       };
    //
    //       if (data == true) {
    //         return data;
    //       }
    //     },
    //     error => {
    //       this.router.navigate(['/auth']);
    //       return error
    //     }
    //   )
  }

  login(code) {
    const body = {
      user: code,
      password: "BHU*nji9"
    };

    return this.post('/sso/login', body).map(
      data => {
        window.localStorage['user'] = JSON.stringify(data);
        return data;
      }
    );
  }

  post(path: string, body: Object = {}, header?: Map<string, string>): Observable<any> {

    return this.http.post(
      `${this.url}${path}`,
      JSON.stringify(body),
      {headers: this.setHeaders(header)}
    )
      .catch(this.formatErrors)
      .map((res: HttpResponse<Response>) => res);
  }

  private setHeaders(header: Map<string, string>): HttpHeaders {
    const isIE11 = document['documentMode'];

    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };

    if (isIE11) {
      headersConfig['If-Modified-Since'] = `${'Sat, 01 Jan 2000 00:00:00 GMT'}`;
      headersConfig['Pragma'] = `${'no-cache'}`;
      headersConfig['Expires'] = `${'Sat, 01 Jan 2000 00:00:00 GMT'}`;
    }


    if (this.userService.getUser()) {
      headersConfig['token'] = `${this.userService.getToken()}`;
    }
    if (header) {
      header.forEach((value, key) => {
        headersConfig[key] = value;
      });
    }
    return new HttpHeaders(headersConfig);
  }

  private formatErrors(error: any) {
    return Observable.throw(error);
  } */

}

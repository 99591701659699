import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PublicPageGuard} from '../../shared/services/public-page.guard';
import { LoginComponent } from './login.component';
import { ForgotComponent } from './forgot/forgot.component';
import { RecoverComponent } from './recover/recover.component';
import {FirstAccessComponent} from "./first-access/first-access.component";

const routes: Routes = [
    { path: 'login', component: LoginComponent, canActivate: [PublicPageGuard] },
    { path: 'login/forgot', component: ForgotComponent , canActivate: [PublicPageGuard] },
    { path: 'login/first-access', component: FirstAccessComponent , canActivate: [PublicPageGuard] },
    { path: 'login/recover', component: RecoverComponent , canActivate: [PublicPageGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }

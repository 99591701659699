<div class="col" style="height: 90vh; justify-content: center; align-items: center; overflow-y: scroll">
  <div class="card" style="width: 90%;">
    <div class="card-header">
      <div class="row">
        <div class="col-11 title-license">
          <h3>Quantidade Total de Contas: {{this.quantTotalAccount}}</h3>
        </div>
      </div>
    </div>
  </div>
<!--  <div class="card" style="width: 90%;">-->
<!--    <div class="card-header">-->
<!--      <div class="row">-->
<!--        <div class="col-11 title-license">-->
<!--          <h3>Estatística de contas por graduação</h3>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="div-line"></div>-->
<!--    <div class="card-body">-->
<!--      <canvas id="myCanvas" baseChart-->
<!--              [datasets]="barChartData"-->
<!--              [labels]="barChartLabels"-->
<!--              [options]="barChartOptions"-->
<!--              [plugins]="barChartPlugins"-->
<!--              [legend]="barChartLegend"-->
<!--              [chartType]="barChartType"></canvas>-->
<!--    </div>-->
<!--  </div>-->
  <div class="card" style="width: 90%;">
    <div class="card-header">
      <div class="row">
        <div class="col-11 title-license">
          <h3>Estatística de contas criadas</h3>
        </div>
      </div>
    </div>
    <div class="div-line"></div>
    <div class="card-body">
      <canvas id="myCanvas" baseChart
              [datasets]="barChartData"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [plugins]="barChartPlugins"
              [legend]="barChartLegend"
              [chartType]="barChartType"></canvas>
    </div>
  </div>

  <div class="card" style="width: 90%;">
      <div class="card-header">
        <div class="row">
          <div class="col-11 title-license">
            <h3>Estatística de contas criadas no mês</h3>
          </div>
        </div>
      </div>
      <div class="div-line"></div>
      <div class="card-body">
        <canvas id="myCanvasMonth" baseChart
                [datasets]="barChartDataAccount"
                [labels]="barChartLabelsAccount"
                [options]="barChartOptions"
                [plugins]="barChartPlugins"
                [legend]="barChartLegend"
                [chartType]="barChartTypeAccount"></canvas>
      </div>
  </div>

  <div class="card" style="width: 90%;">
    <div class="card-header">
      <div class="row">
        <div class="col-11 title-license">
          <h3>Estatística de Emails enviados no mês</h3>
        </div>
      </div>
    </div>
    <div class="div-line"></div>
    <div class="card-body">
      <canvas id="myCanvasMonthEmail" baseChart
              [datasets]="barChartDataEmails"
              [labels]="barChartLabelsEmails"
              [options]="barChartOptions"
              [plugins]="barChartPlugins"
              [legend]="barChartLegend"
              [chartType]="barChartTypeAccount">
      </canvas>
      </div>
    </div>
</div>


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../../../../shared/services/api.service';
import {map} from "rxjs/operators";

@Injectable()
export class ChangeDataService {

  readonly ERROR_INVALID_DATA = '-1';

  constructor(private apiService: ApiService) { }

  updateData(login: string, email: string, fullname: string): Observable<any> {
    const body = {
      login: login,
      email: email,
      fullname: fullname,
    };

    return this.apiService.put('/user/update', body).pipe(map(
      data => {
        if (!this.verifyDataValid(data)) {
          throw new Error(data.code);
        }
        return data;
      }
    ));
  }

  private verifyDataValid = (data): boolean => {
    if (data.code === this.ERROR_INVALID_DATA) {
      return false;
    }
    return true;
  }


}

<section class="login p-fixed d-flex text-center bg-primary common-img-bg" style="background: url(./assets/images/login_background.png) no-repeat center center fixed">
  <!-- Container-fluid starts -->
  <div class="container" >
    <div class="row">
      <div class="col-sm-12">
        <!-- Authentication card start -->
        <div class="login-card card-block auth-body mr-auto ml-auto">
          <div class="md-float-material" >
            <div class="text-center">
              <img class="logo" src="assets/images/logo_login.png" height="144.06px" width="158px" alt="Gradient Able">
            </div>
            <div class="text-center">
              <label class="label-admin">Portal Admin</label>
            </div>
            <form class="auth-box" #loginForm="ngForm">
              <div class="row m-b-20">
                <div class="col-md-12">
                  <h3 class="text-center txt-primary txt-upper">{{'login.title' | translate}}</h3>
                </div>
              </div>
<!--              <div class="row login-flag">-->
<!--                <div class="col-sm-6">-->
<!--                  <div (click)="clickLanguage('pt')" class="flag-item">-->
<!--                    <a><img  src="assets/images/flags/Brazil.png" /></a>-->
<!--                    <p>{{'general.portuguese' | translate}}</p>-->
<!--                    <hr [ngStyle]="{'border-top': lang == 'pt' ? '3px solid rgb(0, 0, 0)' : '2px solid rgba(0, 0, 0, 0.5)' }" />-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-sm-6">-->
<!--                  <div (click)="clickLanguage('en')" class="flag-item">-->
<!--                    <a><img src="assets/images/flags/United-States.png" /></a>-->
<!--                    <p>{{'general.english' | translate}}</p>-->
<!--                    <hr [ngStyle]="{'border-top': lang == 'en' ? '3px solid rgb(0, 0, 0)' : '2px solid rgba(0, 0, 0, 0.5)' }" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <div class="input-group">
                <input  type="text" class="form-control" name="username" required placeholder="{{'login.username' | translate}}" #username="ngModel" [(ngModel)]="usernameValue">
                <span class="md-line"></span>
              </div>
              <div class="input-group">
                <input type="password" (keyup.enter)="(loginForm.valid) ? doLogin(username.value, password.value, remember.value) : notifyErrorWhenCredentialEmpty(username.value, password.value)" required minlength="1" class="form-control" name="password" placeholder="{{'login.password' | translate}}" #password="ngModel" ngModel>
                <span class="md-line"></span>
              </div>
              <div class="row m-t-25 text-left">
                <div class="col-12">
                  <div class="checkbox-fade fade-in-primary d-">
                    <label>
                      <input type="checkbox" name="remember" #remember="ngModel" [(ngModel)]="rememberValue" >
                      <span class="cr square-color"><i class="cr-icon fa fa-check square-color"></i></span>
                      <span class="text-inverse">{{'login.remember' | translate}}</span>
                    </label>
                  </div>
<!--                  <div class="forgot-phone text-right f-right">-->
<!--                    <a class="text-right f-w-600 text-inverse" [routerLink]="['forgot']"> {{'login.forgot' | translate}}</a>-->
<!--                  </div>-->
                </div>
              </div>
              <div class="row m-t-15">
                <div class="col-12">
<!--                  <label class="label-phrase-to-enter">{{'login.phrase-terms1' | translate}}-->
<!--                    <a class="link" target="_blank" href="http://safelatina.com.br/eula/">{{'login.terms' | translate}}</a>-->
<!--                    {{'login.phrase-terms2' | translate}}-->
<!--                    <a class="link" target="_blank" href="http://safelatina.com.br/privacidade/">{{'login.privacy' | translate}}</a>-->
<!--                  </label>-->
                </div>
              </div>
              <div class="row m-t-15">
                <div class="col-md-12">
                  <button (click)="(loginForm.valid) ? doLogin(username.value, password.value, remember.value) : notifyErrorWhenCredentialEmpty(username.value, password.value)" type="button" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">{{'login.sign' | translate}}</button>
                </div>
              </div>
              <hr/>
              <div>
                <app-version></app-version>
              </div>
            </form>
          </div>
          <!-- end of form -->
        </div>
        <!-- Authentication card end -->
      </div>
      <!-- end of col-sm-12 -->
    </div>
    <!-- end of row -->
  </div>
  <!-- end of container-fluid -->
</section>
<ng2-toasty></ng2-toasty>

<!-- confirm update version file modal -->
<app-first-access #modalFirstAccess></app-first-access>


<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step label="Dados do usuário" [stepControl]="accountForm1">

    <div class="passo1" >
      <form [formGroup]="accountForm1">
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Nome Completo:</label>
          <div class="col-sm-10">
            <input formControlName="nomeCompleto" required type="text" class="form-control" minlength="8" maxlength="50" placeholder="" name="name" [(ngModel)]="user.nomeCompleto">
          </div>
        </div>
<!--        <div class="form-group row">-->
<!--          <label class="col-sm-2 col-form-label">OM:</label>-->
<!--          <div class="col-sm-10 ">-->
<!--            <div class="selecao">-->
<!--&lt;!&ndash;              <ngx-select-dropdown formControlName="om" (change)="selectionChanged($event)" [multiple]="false" [(ngModel)]="user.om" [config]="config" [options]="dropdownOptions"></ngx-select-dropdown>&ndash;&gt;-->
<!--              <input formControlName="om" required type="text" class="form-control" minlength="8" maxlength="50" placeholder="" name="name" [(ngModel)]="user.om">-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="form-group row">-->
<!--          <label class="col-sm-2 col-form-label">Código da OM:</label>-->
<!--          <div class="col-sm-10">-->
<!--            <input  formControlName="codigoOm" required type="text" class="form-control" minlength="1" maxlength="50" placeholder="" name="name" [(ngModel)]="user.codigoOm">-->
<!--          </div>-->
<!--        </div>-->
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Cargo:</label>
          <div class="col-sm-10">
            <input formControlName="postoMilitar" required type="text" class="form-control" minlength="2" maxlength="50" placeholder="" name="name" [(ngModel)]="user.postoMilitar">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Nivel do Cargo:</label>
          <div class="col-sm-10">
            <input formControlName="nomeGuerra" required type="text" class="form-control" minlength="3" maxlength="50" placeholder="" name="name" [(ngModel)]="user.nomeGuerra">
          </div>
        </div>
<!--        <div class="form-group row">-->
<!--          <label class="col-sm-2 col-form-label" >Corporativo</label>-->
<!--          <div class="col-sm-10">-->
<!--            <input type="checkbox" (change)="changeChecked($event)"/>-->
<!--          </div>-->
<!--        </div>-->
        <div class="form-group row">
          <label class="col-sm-2 col-form-label" >CPF:</label>
          <div class="col-sm-10">
            <input formControlName="cpf"  [textMask]="{mask: maskCPF}" required type="text" minlength="11" class="form-control" name="name" placeholder="000.000.000-00"
                   [(ngModel)]="user.cpf">
          </div>
        </div>

      </form>
    </div>


    <div>
      <button [disabled]="!accountForm1.valid" required type="button" class="btn btn-primary waves-effect waves-light" (click)="next()" matStepperNext>
        Próximo
      </button>
    </div>
  </mat-step>
  <mat-step label="Email e Senha" [stepControl]="accountForm2">


    <div>
      <form [formGroup]="accountForm2">
        <div class="input-group">
<!--          <select formControlName="email" id="email" class="form-control" name="email" [(ngModel)]="user.email" required>-->
<!--            <option [ngValue]="null" selected>Selecione seu email</option>-->
<!--            <option *ngFor="let email of emails" [value]="email">{{email}}</option>-->
<!--          </select>-->
          <input formControlName="email" required type="email" class="form-control" minlength="2" maxlength="50" placeholder="Digite seu Email" name="name" [(ngModel)]="user.email">
          <span class="md-line"></span>
        </div>
        <div class="input-password">
          <div style="position: relative;">
            <input formControlName="password" required minlength="8" ngClass="{{idx}}" type="password" class="form-control" placeholder="{{'change-password.newPassword' | translate}}"
                   name="password" (keyup)="watchPass($event)" [(ngModel)]="user.password">
            <i class="icon-info-pass"  placement="left"  ngbTooltip="{{'recover.tooltip' | translate}}"></i>
          </div>
          <small ngClass="{{classMsgPassword}}">{{idxMessage}}</small>
        </div>
        <div class="input-group">
          <input formControlName="confirmPassword" type="password" required class="form-control" name="passwordConfirm" placeholder="{{'login.passwordConfirm' | translate}}" >
          <span class="md-line"></span>
        </div>
      </form>
    </div>


    <div>
      <button type="button" class="btn btn-primary waves-effect waves-light" (click)="back()" matStepperPrevious>
        Voltar
      </button>
      <button [disabled]="!accountForm2.valid" type="button" class="btn btn-primary waves-effect waves-light" (click)="finish()">
        Próximo
      </button>

    </div>
  </mat-step>
  <mat-step label="Finalizar">
    <div class="card-header-text">Confirme os dados</div>
    <div class="card-body text-justify content" style="border-left: outset">
      Nome: {{this.user.nomeCompleto}}<br>
      Cargo: {{this.user.postoMilitar}}<br>
      Nivel de cargo: {{this.user.nomeGuerra}}<br>
      CPF: {{this.user.cpf}}<br>
<!--      OM: {{this.user.om}}<br>-->
<!--      Codigo da OM: {{this.user.codigoOm}}<br>-->
      Email: {{this.user.email}}<br>
    </div>
    <button type="button" class="btn btn-primary waves-effect waves-light" (click)="doCreate()" matStepperNext>
      Criar usuário
    </button>
  </mat-step>
</mat-horizontal-stepper>

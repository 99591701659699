import {Component, EventEmitter, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToastyService} from 'ng2-toasty';

import {Observable} from 'rxjs/Observable';
import {ModalBasicComponent} from "../../../../shared/modal-basic/modal-basic.component";
import {SpinnerService} from "../../../../shared/spinner/spinner.service";
import {NotificationService} from "../../../../shared/services/notification.service";
import {RestoreService} from "../restore.service";


@Component({
  selector: 'app-select-restore',
  templateUrl: './select-restore.component.html',
  styleUrls: ['./select-restore.component.scss',
    '../../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../../node_modules/ng2-toasty/style-material.css'],
  encapsulation: ViewEncapsulation.None
})
export class SelectRestoreComponent {

  @ViewChild('modalSharePublic') modalBasic: ModalBasicComponent;
  @Output() sharedPublicEvent = new EventEmitter<string>();
  @Output() unsharedEvent = new EventEmitter<string>();

  /**
   *  internationalization variable
   */
  tryAgainFewMoments: string;
  titleServerError: string;
  requestSend: string;
  restoreSuccess: string;

  hash: string;
  title: string;
  successHash: boolean = false;
  link: string;
  users: Array<any>;
  writePermission: boolean = false;
  id;
  email: string = '';

  checkBoxDisabled: boolean = false;

  constructor(private translate: TranslateService,
              private notification: NotificationService,
              private toastyService: ToastyService,
              public spinnerService: SpinnerService,
              private restoreService: RestoreService) {
  }

  public show(id): void {
    this.writePermission = false;
    this.getInternationalizationVariable();
    this.modalBasic.show();
    this.id = id;
  }

  public hide(): void {
    if (this.successHash){
      this.sharedPublicEvent.emit();
    }
    this.email = '';
    this.modalBasic.hide();
  }

  /**
   * get internationalization from i18n
   */
  getInternationalizationVariable(): void {
    this.translate.get('restore')
      .subscribe(i => {
        this.titleServerError = i.title_error;
        this.tryAgainFewMoments = i.message_error;
        this.requestSend = i.title_success;
        this.restoreSuccess = i.message_success;
      });
  }

  restore(){
    this.spinnerService.showSpinner(true);
    console.log('email: ', this.email);
    console.log('id: ', this.id);
    this.restoreService.restore(this.id.toString(), this.email).subscribe(data=>{
      this.checkNotification(0);
    },error => {
      this.checkNotification(1);
    })
  }

  checkNotification(code: number) {
    if (code === 0) {
      this.notification.notifySuccess(this.toastyService, this.requestSend, this.restoreSuccess);
      this.spinnerService.hideSpinner();
      this.hide();
    } else if (code === 1) {
      this.spinnerService.hideSpinner();
      this.notification.notifyError(this.toastyService, this.titleServerError, this.tryAgainFewMoments);
    }
  }
}

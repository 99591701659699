import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../../../../shared/services/api.service';

@Injectable()
export class ChangePasswordService {

  readonly ERROR_BAD_LOGIN = 'BAD_LOGIN';

  constructor(private apiService: ApiService) { }

  updatePassword(oldPassword: string, newPassword: string): Observable<any> {
    const body = {
      oldPassword: oldPassword,
      newPassword: newPassword
    };

    return this.apiService.put('/user/password', body);
  }

}

import {Component, OnInit, ViewChild} from "@angular/core";
import {BackupService} from "./backup.service";
import {SpinnerService} from "../../../shared/spinner/spinner.service";
import {ToastyService} from "ng2-toasty";
import {NotificationService} from "../../../shared/services/notification.service";
import {TranslateService} from "@ngx-translate/core";
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexFill,
  ApexLegend
} from "ng-apexcharts";
import {interval, Subscription} from "rxjs";
import {startWith, switchMap} from "rxjs/operators";


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
};

@Component({
  selector: 'app-backup-scheduler',
  templateUrl: './backup-scheduler.component.html',
  styleUrls: ['./backup-scheduler.component.scss']
})
export class BackupSchedulerComponent implements OnInit{

  timeInterval: Subscription;
  daysOfWeek = [
    {label: "Segunda", value:2, check:0},
    {label: "Terça", value:3, check:0},
    {label: "Quarta", value:4, check:0},
    {label: "Quinta",value:5, check:0},
    {label: "Sexta", value:6, check:0},
    {label: "Sábado", value:7, check:0},
    {label: "Domingo", value:1, check:0}];

  countsErro = 0;
  countsCompleto = 0;
  totalCounts = 0;

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  schedule = {
    full: {
      hour: "",
      day: 6
    },
    incremental: {
      hour: "",
      days: []
    }
  };

  tryAgainFewMoments = "Tente novamente mais tarde";
  missingParamsTitle = "Faltando alguns dados para agendamento";
  missingParamsInfo = "Certifique-se de preencher todos os campos";
  doBackupTitle = "Erro ao iniciar backup";
  doBackupInfo = "Dentro de instantes sera iniciado trabalho de backup completo";
  successTitle = "Requisição enviada";
  schedulerGetErrorTitle = "Erro ao resgatar backup";
  schedulerSuccessTitle = "Backup agendado com sucesso";
  schedulerErrorTitle = "Erro ao tentar agendar backup";
  schedulerSuccessInfo = "";

  constructor(
    private backupService: BackupService,
    private spinnerService: SpinnerService,
    private toastyService: ToastyService,
    private notification: NotificationService,
    private translateService: TranslateService) {
    this.chartOptions = {
      series: [
        {
          name: "Sucesso",
          data: [this.countsCompleto],
          color: 'green'
        },
        {
          name: "Erro",
          data: [this.countsErro],
          color: 'red'
        },
      ],
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        stackType: "100%"
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      title: {
        text: "Progresso backup"
      },
      xaxis: {
        categories: [this.totalCounts]
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val + "K";
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40
      }
    };
  }

  ngOnInit(): void {
    this.spinnerService.showSpinner(true);
    this.backupService.getConfig().subscribe(
      data => {
        this.spinnerService.hideSpinner();
        if(data && data.full && data.incremental)
          this.convert(data);

      }, err => {
        this.spinnerService.hideSpinner();
        this.notification.notifyError(this.toastyService, this.schedulerGetErrorTitle, this.tryAgainFewMoments);
      }
    );
    this.timeInterval = interval(5000).pipe(
      startWith(0),
      switchMap(() => this.backupService.getProgress())
    ).subscribe(data => {
      this.countsCompleto = data.countsComplete;
      this.countsErro = data.countsError;
      this.chartOptions.series = [
        {
          name: "Sucesso",
          data: [data.countsComplete],
          color: 'green'
        },
        {
          name: "Erro",
          data: [data.countsError],
          color: 'red'
        },
      ];
      this.chartOptions.xaxis = {
        categories: ["Backup realizado em: "+data.countsComplete + " contas", "Erro ao realizar backup em "+
        data.countsError+ " contas", " Total de contas: "+ data.totalCounts]
      }
    }, error => console.log('error: ', error))
  }

  doScheduleBackup() {
    this.spinnerService.showSpinner(true);
    console.log(this.schedule);
    this.schedule.incremental.days = this.daysOfWeek.filter(day => day.check).map(day => day.value);
    this.backupService.saveConfig(this.schedule).subscribe(
      data => {
        this.spinnerService.hideSpinner();
        this.notification.notifySuccess(this.toastyService, this.schedulerSuccessTitle, this.schedulerSuccessInfo);
      }, error => {
        this.spinnerService.hideSpinner();
        this.notification.notifyError(this.toastyService, this.schedulerErrorTitle, this.tryAgainFewMoments);
      }
    );
  }

  doBackup() {
    this.spinnerService.showSpinner(true);
    this.backupService.executeBackup().subscribe(
      data => {
        this.spinnerService.hideSpinner();
        this.notification.notifySuccess(this.toastyService, this.successTitle, this.doBackupInfo);
      }, error => {
        this.spinnerService.hideSpinner();
        this.notification.notifyError(this.toastyService, this.doBackupTitle, this.tryAgainFewMoments);
      }
    );
  }

  notifyFormError() {
    this.notification.notifyError(this.toastyService, this.missingParamsTitle, this.missingParamsInfo);
  }

  convert(data) {
    data.incremental.days.forEach(day => {
      let check = this.daysOfWeek.find(d => d.value == day);
      check.check = 1;
    });
    this.schedule = data;
  }

}

import { AfterViewInit, Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PopupService } from '@ng-bootstrap/ng-bootstrap/util/popup';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastyService } from 'ng2-toasty';

import { PreferenceService } from '../../../shared/services/preference.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { AuthService } from '../../../shared/services/auth.service';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import { RecoverService } from './recover.service';


@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['../login.component.scss',
  '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
  '../../../../../node_modules/ng2-toasty/style-default.css',
  '../../../../../node_modules/ng2-toasty/style-material.css'],
  encapsulation: ViewEncapsulation.None
})
export class RecoverComponent implements OnInit, AfterViewInit {

  public lang: string;
  private login: string;
  private resetCode: string;
  public block = false;

  /**
   *  internationalization variable
   */
  enterPasswordFailed: string;
  enterPasswordConfirmFailed: string;
  enterCredentialIsEmpty: string;
  enterValidPassword: string;
  titleMissing: string;
  titleInvalid: string;
  titleError: string;
  titleUserLogged: string;
  logoutUser: string;
  tryAgainFewMoments: string;
  titleSuccess: string;
  passwordInvalidSize: string;
  passwordChange: string;
  passwordNotChange: string;
  passwordNotStrong: string;
  invalidData: string;

  idx: string;
  idxMessage: string;
  classMsgPassword: string;

  newPasswordValue: string;

  constructor(private translate: TranslateService,
    private service: AuthService,
    private preference: PreferenceService,
    private recoverService: RecoverService,
    private router: Router,
    private activadedRoute: ActivatedRoute,
    private notification: NotificationService,
    private toastyService: ToastyService,
    public spinnerService: SpinnerService) {}

  ngOnInit() {
    this.initLanguage();
    this.activadedRoute.queryParamMap.subscribe(params => {
      this.login = params.get('login');
      this.resetCode = params.get('resetCode');
      if (this.isEmpty(this.login) || this.isEmpty(this.resetCode)) {
        this.router.navigate(['login']);
      }
    });
  }

  ngAfterViewInit() {
    this.getInternationalizationRecoverVariable();
    this.getInternationalizationLoginVariable();
  }

  /**
   * get language set in localStorage
   */
   initLanguage(): void {
     if (this.preference.getPreference()) {
       const lang = this.preference.getPreference();
       this.useLanguage(lang.lang);
     }
   }
   /**
    * If session salved, block button
    */
   checkSessionSaved() {
     if (this.service.isSignedIn()) {
       this.notification.notifyError(this.toastyService, this.titleUserLogged, this.logoutUser, 15000);
       this.block = true;
     }
   }

  /**
    * Change language
    * @param language
    */
   useLanguage(language: string) {
     this.translate.use(language);
     this.lang = language;
   }

   /**
    * When the inputs are empty
    * @param username input value username
    * @param email input value email
    */
   notifyErrorWhenCredentialEmpty(password: String, passwordConfirm: String) {
     this.getInternationalizationLoginVariable();
     if (this.isEmpty(password) && this.isEmpty(passwordConfirm)) {
       this.notification.notifyError(this.toastyService, this.titleMissing, this.enterCredentialIsEmpty);
     } else if (this.isEmpty(password)) {
       this.notification.notifyError(this.toastyService, this.titleMissing, this.enterPasswordFailed);
     } else if (this.isEmpty(passwordConfirm)) {
       this.notification.notifyError(this.toastyService, this.titleMissing, this.enterPasswordConfirmFailed);
     } else if (password.length < 8) {
       this.notification.notifyError(this.toastyService,  this.titleInvalid, this.passwordInvalidSize);
     }
   }

   /**
    * get internationalization from i18n
    */
   getInternationalizationLoginVariable(): void {
     this.translate.get('login')
     .subscribe(i => {
       this.enterPasswordFailed = i.error_enter_password;
       this.enterPasswordConfirmFailed = i.error_enter_passwordConfirm;
       this.enterCredentialIsEmpty = i.error_credential_empty;
       this.titleMissing = i.error_missing_data;
       this.titleError = i.error_server;
       this.tryAgainFewMoments = i.error_try_again_few_moments;
       this.titleInvalid = i.error_invalid_data;
       this.passwordInvalidSize = i.error_password_size;
     });
   }

   isEmpty(str) {
     return (!str || 0 === str.length);
   }

   /**
    * get internationalization from i18n
    */
   getInternationalizationRecoverVariable(): void {
     this.translate.get('recover')
     .subscribe(i => {
       this.titleInvalid = i.error_invalid_password;
       this.passwordNotStrong = i.error_password_strong;
       this.enterValidPassword = i.diff_password;
       this.titleSuccess = i.password_change;
       this.passwordChange = i.success_password;
       this.passwordNotChange = i.error_password;
       this.titleUserLogged = i.logged_user;
       this.logoutUser = i.logout_user;
       this.invalidData = i.error_invalid_data;
       this.checkSessionSaved();
     });
   }

   doRecover(password, passwordConfirm) {
     if (password !== passwordConfirm) {
       this.notification.notifyError(this.toastyService, this.titleInvalid, this.enterValidPassword);
     } else if (this.idx !== 'strong') {
       this.notification.notifyError(this.toastyService, this.titleInvalid, this.passwordNotStrong);
       return;
     } else {
       this.spinnerService.showSpinner(true);
       this.recoverService.changePassword(this.login, this.resetCode, password).subscribe(
         data => {
           this.spinnerService.showSpinner(false);
           this.notification.notifySuccess(this.toastyService, this.titleSuccess, this.passwordChange);
           setTimeout ( () => {
             this.spinnerService.hideSpinner();
             this.router.navigate(['login']);
           }, 3500);
         },
         err => {
           this.spinnerService.hideSpinner();
           if (err.status === 400) {
             this.notification.notifyError(this.toastyService, this.titleInvalid, this.invalidData);
           } else {
             this.notification.notifyError(this.toastyService, this.titleError, this.passwordNotChange);
           }
         }
       );
     }
   }

   watchPass(event: any) {
     this.getClassStrength(this.measureStrength(this.newPasswordValue));
   }

   private measureStrength(p: string) {

     let _force = 0;
     const _regex = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g;
     const _lowerLetters = /[a-z]+/.test(p);
     const _upperLetters = /[A-Z]+/.test(p);
     const _numbers = /[0-9]+/.test(p);
     const _symbols = _regex.test(p);
     const _flags = [_lowerLetters, _upperLetters, _numbers, _symbols];
     let _passedMatches = 0;
     if (p.length <= 7) {
       this.idx = 'weak';
       this.classMsgPassword = 'weak-m';
       if (this.lang === 'pt') {
         this.idxMessage = 'Senha fraca, tente outra!';
       } else if (this.lang === 'en') {
         this.idxMessage = 'Weak password, try another one!';
       }
       _force = 0;
     } else {
       for (let _i = 0, _flags_1 = _flags; _i < _flags_1.length; _i++) {
         const _flag = _flags_1[_i];
         _passedMatches += _flag === true ? 1 : 0;
       }
       _force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
       _force += _passedMatches * 10;
       _force = (p.length <= 6) ? Math.min(_force, 10) : _force;
       _force = (_passedMatches === 1) ? Math.min(_force, 10) : _force;
       _force = (_passedMatches === 2) ? Math.min(_force, 20) : _force;
       _force = (_passedMatches === 3) ? Math.min(_force, 30) : _force;
       _force = (_passedMatches === 4) ? Math.min(_force, 40) : _force;
       return _force;
     }
   }

   private getClassStrength(s: number) {

     if (s <= 10) {
       this.idx = 'weak';
       this.classMsgPassword = 'weak-m';
       if (this.lang === 'pt') {
         this.idxMessage = 'Senha fraca, tente outra!';
       } else if (this.lang === 'en') {
         this.idxMessage = 'Weak password, try another one!';
       }
     } else if (s <= 20) {
       this.idx = 'medium';
       this.classMsgPassword = 'medium-m';
       if (this.lang === 'pt') {
         this.idxMessage = 'Senha média, continue tentando!';
       } else if (this.lang === 'en') {
         this.idxMessage = 'Average password, keep trying!';
       }
     } else if (s <= 30) {
       this.idx = 'medium';
       this.classMsgPassword = 'medium-m';
       if (this.lang === 'pt') {
         this.idxMessage = 'Senha média, continue tentando!';
       } else if (this.lang === 'en') {
         this.idxMessage = 'Average password, keep trying!';
       }
     } else if (s <= 40) {
       this.idx = 'strong';
       this.classMsgPassword = 'strong-m';
       if (this.lang === 'pt') {
         this.idxMessage = 'Senha forte, muito bem!';
       } else if (this.lang === 'en') {
         this.idxMessage = 'Password strong, very well!';
       }
     }
   }

}

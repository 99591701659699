import {Injectable} from "@angular/core";
import {ApiZimbraService} from "../../../shared/services/api-zimbra.service";

@Injectable()
export class RestoreService {

  static PATH_BACKUP = "/api/backup";
  static PATH = "/api/restore";

  constructor(private apiZimbraService: ApiZimbraService) {
  }

  getListBackups(){
    const header = new Map();
    return this.apiZimbraService.get(RestoreService.PATH_BACKUP,header);
  }

  restore(id, email){
    const header = new Map();
    header.set('backupId', id);
    if (email)
      header.set('email', email);
    return this.apiZimbraService.post(RestoreService.PATH, null, header);
  }
}

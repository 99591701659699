<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step label="Dados do usuário" [stepControl]="accountForm1">

    <div>
      <form [formGroup]="accountForm1">
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">OM:</label>
          <div class="col-sm-10">
            <ngx-select-dropdown formControlName="om" (change)="selectionChanged($event)" [multiple]="false" [(ngModel)]="user.codigoOm" [config]="config" [options]="dropdownOptions"></ngx-select-dropdown>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Nome da completo da OM:</label>
          <div class="col-sm-10">
            <input disabled formControlName="nomeCompleto" required type="text" class="form-control" minlength="8" maxlength="50" placeholder="" name="name" [(ngModel)]="user.nomeCompleto">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Código OM:</label>
          <div class="col-sm-10">
            <input disabled formControlName="codigoOm" required type="text" class="form-control" minlength="1" maxlength="50" placeholder="" name="name" [(ngModel)]="user.codigoOm">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Nome da função:</label>
          <div class="col-sm-10">
            <input formControlName="postoMilitar" required type="text" class="form-control" minlength="2" maxlength="50" placeholder="" name="name" [(ngModel)]="user.postoMilitar">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label" >Identidade do militar:</label>
          <div class="col-sm-10">
            <input disabled formControlName="cpf" required type="text" class="form-control" name="code" id="code" placeholder=""
                   [(ngModel)]="user.cpf">
          </div>
        </div>
      </form>
    </div>


    <div>
      <button [disabled]="!accountForm1.valid" type="button" class="btn btn-primary waves-effect waves-light" (click)="next()" matStepperNext>
        Próximo
      </button>
    </div>
  </mat-step>
  <mat-step label="Email e Senha" [stepControl]="accountForm2">


    <div>
      <form [formGroup]="accountForm2">
        <div class="input-group">
          <select formControlName="email" id="email" class="form-control" name="email" [(ngModel)]="user.email" required>
            <option [ngValue]="null" selected>Selecione seu email</option>
            <option *ngFor="let email of emails" [value]="email">{{email}}</option>
          </select>
          <span class="md-line"></span>
        </div>
      </form>
    </div>


    <div>
      <button type="button" class="btn btn-primary waves-effect waves-light" (click)="back()" matStepperPrevious>
        Voltar
      </button>
      <button [disabled]="!accountForm2.valid" type="button" class="btn btn-primary waves-effect waves-light" (click)="finish()">
        Próximo
      </button>

    </div>
  </mat-step>
  <mat-step label="Finalizar">
    <div class="card-header-text">Confirme os dados</div>
    <div class="card-body text-justify content" style="border-left: outset">
      <p> <b>{{this.user.nomeCompleto}}</b></p><br>
      Email: {{this.user.email}}<br>
      Função: {{this.user.postoMilitar}}<br>
      OM: {{this.user.om}}<br>
      Codigo da OM: {{this.user.codigoOm}}<br>

    </div>
    <button type="button" class="btn btn-primary waves-effect waves-light" (click)="doCreate()" matStepperNext>
      Criar usuário
    </button>
  </mat-step>
</mat-horizontal-stepper>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AppComponent} from './app.component';
import {PrivatePageGuard} from './shared/services/private-page.guard';

const appRoutes: Routes = [
  {
    path: '',
    component: AppComponent,
    canActivate: [PrivatePageGuard],
    children: [
      {
        path: '**',
        redirectTo: ''
      },
      {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
      },
    ]
  }];


@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}

<app-modal-basic #modalChangePassword>
  <div class="app-modal-header">
    <h4 class="modal-title">{{'change-password.title' | translate}}</h4>
    <span class="modal-subtitle">{{'change-password.subtitle' | translate}}</span>
    <button type="button" class="close basic-close" (click)="modalChangePassword.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <form #changePasswordForm="ngForm">
      <label class="small">{{'change-password.labelOldPassword' | translate}}</label>
      <div class="input-group">
          <input  type="password" class="form-control" name="oldPassword" required
            placeholder="{{'change-password.oldPassword' | translate}}" #oldPassword="ngModel" [(ngModel)]="oldPasswordValue">
          <span class="md-line"></span>
      </div>
      <label class="small">{{'change-password.labelNewPassword' | translate}}</label>
      <div class="input-password">
        <div class="input-info">
          <input required minlength="8" ngClass="{{idx}}" type="password" class="form-control" placeholder="{{'change-password.newPassword' | translate}}"
            name="password" (keyup)="watchPass($event)" [(ngModel)]="newPasswordValue">
          <i class="icon-info-pass"  placement="right"  ngbTooltip="{{'recover.tooltip' | translate}}"></i>
        </div>
        <small ngClass="{{classMsgPassword}}">{{idxMessage}}</small>
      </div>
      <label class="small">{{'change-password.labelConfirmNewPassword' | translate}}</label>
      <div class="input-group">
          <input type="password" required minlength="1" class="form-control" name="confirmNewPassword" minlength="8"
            placeholder="{{'change-password.confirmNewPassword' | translate}}" #confirmNewPassword="ngModel" [(ngModel)]="confirmNewPasswordValue">
          <span class="md-line"></span>
        </div>
    </form>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-danger waves-effect " (click)="modalChangePassword.hide()">{{'general.cancel' | translate}}</button>
    <button type="button" class="btn btn-primary waves-effect waves-light"
            (click)="(changePasswordForm.valid) ? doChangePassword() : notifyErrorWhenPasswordEmpty()">
            {{'general.confirm' | translate}}
    </button>
  </div>
</app-modal-basic>
<ng2-toasty></ng2-toasty>

import {Component, OnInit, ViewChild} from '@angular/core';
import {UserModel} from "../new-user/user.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatStepper} from "@angular/material/stepper";
import {SpinnerService} from "../../../../shared/spinner/spinner.service";
import {AccountService} from "../account.service";
import {Router} from "@angular/router";
import {ApiEbcorpService} from "../../../../shared/services/api-ebcorp.service";
import {ToastyService} from 'ng2-toasty';

@Component({
  selector: 'app-new-user-office',
  templateUrl: './new-user-office.component.html',
  styleUrls: ['./new-user-office.component.css']
})
export class NewUserOfficeComponent implements OnInit {


  user: UserModel;
  accountForm1: FormGroup;
  accountForm2: FormGroup;
  emails = [];
  dataModel;
  @ViewChild("stepper") stepper: MatStepper;

  config = {
    displayKey:"sigla",
    search:true,
    height: 'auto',
    placeholder:'Selecione',
    customComparator: ()=>{},
    limitTo: 0,
    moreText: 'more',
    noResultsFound: 'OM não encontrada!',
    searchPlaceholder:'Pesquise',
    searchOnKey: 'sigla'
  }

  dropdownOptions = []

  constructor(
    private apiEbcorpService: ApiEbcorpService,
    private _formBuilder: FormBuilder,
    private spinnerService: SpinnerService,
    private accountService: AccountService,
    private router: Router,
    private toastService:ToastyService) { }

  ngOnInit(): void {
    this.getOms();
    this.user = new UserModel();
    this.accountForm1 = this._formBuilder.group({
      nomeCompleto: ['', Validators.required],
      postoMilitar: ['', Validators.required],
      cpf: ['', Validators.required],
      codigoOm: ['', Validators.required],
    });
    this.accountForm2 = this._formBuilder.group({
      email: ['', Validators.required],
    });
  }

  next() {
    this.generateEmail();
  }

 generateEmail(){
    let newOm = this.user.om.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
    // let newEmail = this.user.postoMilitar.toLowerCase().trim() + '.' + newOm.toLowerCase().trim() + '@eb.mil.br'
     this.accountService.getEmails(newOm,this.user.postoMilitar.toLowerCase().trim()).subscribe(value => {
       this.emails = value.mails
     }, error => {
       this.toastService.error("Erro ao recuperar opções de Email.");
     })

  }

  back(){

  }

  finish() {
    if (this.stepper !== undefined) {
      this.stepper.next();
    }
  }

  doCreate() {
    this.spinnerService.showSpinner(true)
    this.accountService.createUserOffice(this.user, this.user.email,this.user.password).subscribe(data=>{
      this.spinnerService.hideSpinner();
      this.toastService.success("Conta criada com sucesso.")
      this.router.navigate(['']);
    },error => {
      console.log('error: ', error);
      this.toastService.error("Erro ao criar conta.")
      this.spinnerService.hideSpinner();
    });
  }

  selectionChanged(event){
    let om = event.value;
    this.user.om = om.sigla;
    this.user.nomeCompleto = om.nome;
    this.user.codigoOm = om.codom;
    this.user.cpf = om.codom;
    console.log('select: ', event.value)
  }

  getOms(){
    this.apiEbcorpService.get('/om').subscribe(data => {
      console.log('oms: ', data)
      this.dropdownOptions = data;
    },error => {
      this.toastService.error("Erro ao recuperar OM")
    })
  }
}
